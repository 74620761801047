import { useAnalysisScreenContext } from "@/contexts/analysisScreenContext";
import useAnimationFrame from "@/hooks/useAnimationFrame";
import { hexToRgbAlpha } from "@/utils/common";
import { useCallback } from "react";

export default function AnimationCanvas() {
  const {
    animationCanvasRef,
    imageCardDetectorRef,
    lColorBlockGsapContextRef,
    rColorBlockGsapContextRef,
    roiGsapContextRef,
  } = useAnalysisScreenContext();

  const renderAnimationFrame = useCallback(
    ({
      color,
      roiColor,
      leftColorBlockColor,
      rightColorBlockColor,
    }: {
      color: string;
      roiColor: string;
      leftColorBlockColor: string;
      rightColorBlockColor: string;
    }) => {
      if (!animationCanvasRef.current) return;
      const canvas = animationCanvasRef.current;
      const canvasContext = canvas.getContext("2d");
      canvasContext?.clearRect(0, 0, canvas.width, canvas.height);

      if (
        imageCardDetectorRef.current &&
        imageCardDetectorRef.current.markerCorners &&
        canvasContext
      ) {
        const imageCardDetector = imageCardDetectorRef.current;
        const markerCorners = imageCardDetector.markerCorners;
        const markersColor = color;

        Object.values(markerCorners).forEach((marker) => {
          canvasContext.strokeStyle = markersColor;
          canvasContext.lineWidth = 3;
          canvasContext.beginPath();
          canvasContext.moveTo(marker[0].x, marker[0].y);
          for (let i = 1; i < 4; i++) {
            canvasContext.lineTo(marker[i].x, marker[i].y);
          }
          canvasContext.closePath();
          canvasContext.stroke();
        });
        const drawColorBlock = (cells: any[], color: string) => {
          cells.forEach((cell) => {
            const { corners } = cell;
            canvasContext.fillStyle = color;
            canvasContext.strokeStyle = color;
            canvasContext.lineWidth = 2;
            canvasContext.beginPath();
            canvasContext.moveTo(corners[0][0], corners[0][1]);
            for (let k = 1; k < 4; k++) {
              canvasContext.lineTo(corners[k][0], corners[k][1]);
            }
            canvasContext.closePath();
            canvasContext.stroke();
          });
        };
        drawColorBlock(
          imageCardDetector.leftColorBlockCells || [],
          leftColorBlockColor
        );
        drawColorBlock(
          imageCardDetector.rightColorBlockCells || [],
          rightColorBlockColor
        );
        const roiPoints: any = imageCardDetector.roiPoints;
        canvasContext.strokeStyle = roiColor;
        canvasContext.lineWidth = 5;
        canvasContext.beginPath();
        canvasContext.moveTo(roiPoints[0].x, roiPoints[0].y);
        for (let i = 1; i < 4; i++) {
          canvasContext.lineTo(roiPoints[i].x, roiPoints[i].y);
        }
        canvasContext.closePath();
        canvasContext.stroke();
      }
    },
    [animationCanvasRef, imageCardDetectorRef]
  );

  const animationFrameCallback = useCallback(() => {
    const color = "#5FE2CB";
    renderAnimationFrame({
      color,
      roiColor: hexToRgbAlpha(color, roiGsapContextRef.current.alpha),
      leftColorBlockColor: hexToRgbAlpha(
        color,
        lColorBlockGsapContextRef.current.alpha
      ),
      rightColorBlockColor: hexToRgbAlpha(
        color,
        rColorBlockGsapContextRef.current.alpha
      ),
    });
  }, [
    lColorBlockGsapContextRef,
    rColorBlockGsapContextRef,
    renderAnimationFrame,
    roiGsapContextRef,
  ]);

  useAnimationFrame(animationFrameCallback);

  return (
    <canvas ref={animationCanvasRef} className="absolute top-0 left-0"></canvas>
  );
}
