import { atom } from "jotai";

export const initialMessages = [
  {
    id: "1234",
    message:
      "Hello! How can I help? \n\n You can pick a topic below or type a question of your own.",
    isUser: false,
  },
];

export const conversationIdAtom = atom("");

export const messagesAtom = atom(initialMessages);

export const resetMessagesAtom = atom(null, (get, set) =>
  set(messagesAtom, initialMessages)
);

export const chatResponseLoadingAtom = atom(false);
