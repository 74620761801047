import { useForm } from "react-hook-form";
import { Drawer, DrawerContent } from "../ui/drawer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cn } from "@/lib/utils";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useAppContext } from "@/contexts/appContext";
import { Checkbox } from "../ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { DateTime } from "luxon";
import { IScanResult } from "@/types/ScanResult";
import * as zod from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from "sonner";
import { DatePicker } from "../DatePicker";
import { ReactComponent as EmailSentSvg } from "@/assets/img/email-sent.svg";
import { Link } from "react-router-dom";
import { supabaseService } from "@/services/supabaseService";
import { ChevronLeft, X } from "lucide-react";
import {
  NUMERIC_MEASUREMENT,
  NUMERIC_MEASUREMENT_UNITS,
} from "@/utils/variables";
import { ELegalSexOptions } from "@/types/common";

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  resultData: IScanResult;
}

enum FormSteps {
  Email = "email",
  PersonalInfo = "personal-info",
  EmailSent = "email-sent",
}

const dateFormat = "y-MM-dd";
const dateLocale = "en-US";

const formSchema = zod.object({
  user_info: zod.object({
    email: zod.string().email(),
    first_name: zod.string().optional().nullable(),
    last_name: zod.string().optional().nullable(),
    age: zod.string().optional().nullable(),
    sex: zod.string().optional().nullable(),
    date_of_birth: zod.string().optional().nullable(),
    patient_id: zod.string().optional().nullable(),
    specimen_id: zod.string().optional().nullable(),
    account_id: zod.string().optional().nullable(),
  }),
  test_result: zod.custom(() => true),
});

const convertEmptyStringsToNull = (data: any) => {
  return Object.fromEntries(
    Object.entries(data).map((entry: any) => {
      const [key, value] = entry;
      if (typeof value === "string" && value.trim().length === 0) {
        return [key, null];
      }
      return entry;
    })
  );
};

export default function SendResultPdfModal({
  open,
  onOpenChange,
  resultData,
}: IProps) {
  const form = useForm<any>({
    mode: "onBlur",
    resolver: zodResolver(formSchema),
    defaultValues: {
      test_result: {
        id: resultData.scan_uuid,
        date_collected: DateTime.local().toFormat(dateFormat),
        date_received: DateTime.local().toFormat(dateFormat),
        date_reported: DateTime.local().toFormat(dateFormat),
        fasting: "No",
        predictions: resultData.predictions,
      },
      user_info: {
        first_name: null,
        last_name: null,
        age: null,
        sex: null,
        date_of_birth: null,
        patient_id: "None",
        specimen_id: "None",
        account_id: "None",
        email: "",
      },
      logged_in_user_info: {
        include_user_name: false,
        include_dob: false,
        include_legal_sex: false,
      },
    },
  });
  const { session, isLoggedIn } = useAppContext();
  const [step, setStep] = useState(FormSteps.Email);
  const [isLoading, setIsLoading] = useState(false);
  const supabase = supabaseService.getClient();

  const loggedInUserData = useMemo(() => {
    const user = session?.user;
    const data = {
      first_name: "",
      last_name: "",
      full_name: "",
      date_of_birth: "",
      sex: "",
      id: undefined,
      email: "",
    };

    if (user) {
      data.first_name = user.user_metadata?.first_name || "";
      data.last_name = user.user_metadata?.last_name || "";
      data.full_name = `${data.first_name} ${data.last_name}`.trim();
      data.id = user.id;
      data.email = user.user_metadata?.email || "";
    }

    return data;
  }, [session]);

  const handleContinueEmail = useCallback(async () => {
    const isValidEmail = await form.trigger("user_info.email");
    if (isValidEmail) {
      setStep(FormSteps.PersonalInfo);
    }
  }, [form]);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);
        const { data: resultIdResponse, error } = await supabase.rpc(
          "get_scan_id_by_uuid",
          {
            scan_uuid_input: resultData.scan_uuid,
          }
        );
        if (error) throw error;
        const resultId = resultIdResponse || 0;
        const negativeText = "Negative";
        const predictions = Object.fromEntries(
          Object.entries(data.test_result.predictions).map((entry: any) => {
            const [key, value] = entry;
            if (value === 0) {
              return [key, negativeText];
            } else {
              const newValue =
                NUMERIC_MEASUREMENT[key] && NUMERIC_MEASUREMENT[key][value]
                  ? NUMERIC_MEASUREMENT[key][value]
                  : "N/A";

              return [key, newValue];
            }
          })
        );
        const units = Object.fromEntries(
          Object.entries(NUMERIC_MEASUREMENT_UNITS).map((entry) => {
            const [key, value] = entry;
            if (value === negativeText) {
              return [key, null];
            }
            return entry;
          })
        );
        const payload = {
          ...data,
          user_info: convertEmptyStringsToNull(data.user_info),
          test_result: {
            ...data.test_result,
            id: resultId,
            predictions,
            units,
          },
        };

        if (
          data.user_info.sex &&
          typeof localStorage !== "undefined" &&
          localStorage !== null
        ) {
          localStorage.setItem("user_legal_sex", data.user_info.sex);
        }

        await axios.post(
          process.env.REACT_APP_EMAIL_RESULTS_PDF_API || "",
          payload
        );
        setStep(FormSteps.EmailSent);
      } catch (error: any) {
        console.error(error);
        toast.error(error.message || "Error");
      } finally {
        setIsLoading(false);
      }
    },
    [resultData, supabase]
  );

  const previousStep = useMemo(() => {
    switch (step) {
      case FormSteps.PersonalInfo:
        return FormSteps.Email;
      default:
        return undefined;
    }
  }, [step]);

  useEffect(() => {
    if (loggedInUserData) {
      const { id: userId } = loggedInUserData;
      const accountId = userId ? (userId as string).slice(0, 8) : undefined;

      if (loggedInUserData.full_name) {
        form.setValue("user_info.first_name", loggedInUserData.first_name);
        form.setValue("user_info.last_name", loggedInUserData.last_name);
        form.setValue("logged_in_user_info.include_user_name", true);
      }

      form.setValue("user_info.account_id", accountId || null);
      form.setValue("user_info.patient_id", accountId || null);
      form.setValue("user_info.specimen_id", accountId || null);
      form.setValue("user_info.email", loggedInUserData.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserData]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange} modal={true}>
      <DrawerContent
        onAnimationEnd={(open) => {
          if (!open) form.reset();
        }}
        className="bg-white"
      >
        <div className="w-full max-w-screen-sm mx-auto py-5">
          {step !== FormSteps.EmailSent && (
            <div className="mx-auto max-w-screen-sm mb-5 grid grid-cols-header gap-4 items-center px-5">
              {previousStep ? (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setStep(previousStep)}
                  className="text-ribbon-cobalt-700"
                >
                  <ChevronLeft size={30} />
                </Button>
              ) : (
                <div></div>
              )}
              <div className="flex flex-col items-center justify-center">
                <div className="font-bold">Email Results</div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => onOpenChange(false)}
                className="text-ribbon-cobalt-700"
              >
                <X size={30} />
              </Button>
            </div>
          )}
          <div className=" max-h-[calc(100vh-120px)] overflow-y-auto px-5">
            <Form {...form}>
              <div className={cn(step !== FormSteps.Email && "hidden")}>
                <p className="text-center mb-5 px-4">
                  Enter the email address you would like the results report sent
                  to.
                </p>
                <FormField
                  control={form.control}
                  name="user_info.email"
                  render={({ field }) => (
                    <FormItem className="mb-8">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input type="email" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="button"
                  variant="outline"
                  className="w-full"
                  onClick={handleContinueEmail}
                >
                  Continue
                </Button>
              </div>
              <div className={cn(step !== FormSteps.PersonalInfo && "hidden")}>
                <p className="text-center mb-5">
                  {isLoggedIn ? (
                    <>
                      Provide any additional information you’d like included on
                      your result report. This information will be saved to your
                      profile."
                    </>
                  ) : (
                    <>
                      Provide any additional information you’d like included on
                      your result report.
                    </>
                  )}
                </p>
                <div className="space-y-5">
                  {loggedInUserData.first_name && loggedInUserData.last_name ? (
                    <FormField
                      control={form.control}
                      name="logged_in_user_info.include_user_name"
                      render={({ field }) => {
                        const handleChange = (checked: boolean) => {
                          form.setValue(
                            "user_info.first_name",
                            checked ? loggedInUserData.first_name : null
                          );
                          form.setValue(
                            "user_info.last_name",
                            checked ? loggedInUserData.last_name : null
                          );
                          field.onChange(checked);
                        };

                        return (
                          <FormItem className="flex items-center justify-between">
                            <div className="space-y-1">
                              <FormLabel>Name</FormLabel>
                              <div>{loggedInUserData.full_name}</div>
                            </div>
                            <FormControl>
                              <Checkbox
                                checked={field.value}
                                onCheckedChange={handleChange}
                              />
                            </FormControl>
                          </FormItem>
                        );
                      }}
                    />
                  ) : (
                    <>
                      <FormField
                        control={form.control}
                        name="user_info.first_name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              First Name{" "}
                              <span className="font-normal text-ribbon-stone-700">
                                (Optional)
                              </span>
                            </FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="user_info.last_name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Last Name{" "}
                              <span className="font-normal text-ribbon-stone-700">
                                (Optional)
                              </span>
                            </FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                  <FormField
                    control={form.control}
                    name="user_info.date_of_birth"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Date of Birth{" "}
                          <span className="font-normal text-ribbon-stone-700">
                            (Optional)
                          </span>
                        </FormLabel>
                        <DatePicker
                          displayFormat={"PPP"}
                          date={
                            field.value
                              ? DateTime.fromFormat(field.value, dateFormat)
                                  .setLocale(dateLocale)
                                  .toJSDate()
                              : undefined
                          }
                          setDate={(date: Date | undefined) =>
                            field.onChange(
                              date
                                ? DateTime.fromJSDate(date)
                                    .setLocale(dateLocale)
                                    .toFormat(dateFormat)
                                : ""
                            )
                          }
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="user_info.sex"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Legal Sex{" "}
                          <span className="font-normal text-ribbon-stone-700">
                            (Optional)
                          </span>
                        </FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value={ELegalSexOptions.Male}>
                              {ELegalSexOptions.Male}
                            </SelectItem>
                            <SelectItem value={ELegalSexOptions.Female}>
                              {ELegalSexOptions.Female}
                            </SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div>
                    <Button
                      className="w-full mt-2"
                      onClick={form.handleSubmit(onSubmit)}
                      disabled={isLoading}
                    >
                      Email Results Report
                    </Button>
                  </div>
                </div>
              </div>
              <div className={cn(step !== FormSteps.EmailSent && "hidden")}>
                <EmailSentSvg className="mx-auto mb-8" />
                <h3 className="text-center mb-4 font-bold text-2xl">
                  Results sent!
                </h3>
                {!isLoggedIn && (
                  <p className="text-center mb-7">
                    Would you like to create an account and save your
                    information for future tests?
                  </p>
                )}
                <div className="space-y-3">
                  {!isLoggedIn && (
                    <Button variant="outline" className="w-full" asChild>
                      <Link to="/auth">Create account</Link>
                    </Button>
                  )}
                  <Button
                    className="w-full"
                    onClick={() => onOpenChange(false)}
                  >
                    Return to Results
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
