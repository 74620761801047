import BotImage from "@/assets/img/de1b3c7ec885da63d0fc95324d23fbcd.png";
import { conversationIdAtom } from "@/store/atoms/messageAtom";
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../ui/button";

interface IProps {
  setStartConversation: (value: boolean) => void;
}
const ChatBotWelcomePage = ({ setStartConversation }: IProps) => {
  const [, setConversationId] = useAtom(conversationIdAtom);
  return (
    <div className="flex flex-col items-center mt-14">
      <img src={BotImage} className="rounded-2xl" width="80px" height="80px" />

      <div className="relative max-w-[65%] p-4 bg-ribbon-slate-100 text-white rounded-lg mt-8">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[15px] border-b-slate-100" />

        <h2 className="font-bold text-ribbon-slate-900 flex items-center justify-center text-xl">
          Hi! I’m Evelyn{" "}
          <span role="img" aria-label="waving hand">
            👋
          </span>
        </h2>
        <p className="text-ribbon-slate-700 mt-1 text-sm text-center">
          Any time you need help, I’m here to get the answers you need – Just
          look for my{" "}
          <span className="inline-block align-middle">
            <img
              src={BotImage}
              className="rounded-sm"
              width="18px"
              height="18px"
            />
          </span>{" "}
          icon.
        </p>
      </div>
      <p className="mt-16 text-ribbon-slate-500 text-sm text-center mx-4 font-normal">
        *This tool is not intended for use in diagnosing diseases or other
        conditions; determining or monitoring the state of health; or curing,
        mitigating, treating or preventing and diseases. 
      </p>
      <div className="flex flex-col p-5 max-w-screen-sm mx-auto w-full">
        <Button
          asChild
          className="w-full cursor-pointer"
          onClick={() => {
            setStartConversation(true);
            setConversationId(uuidv4());
          }}
        >
          <p>Start a conversation</p>
        </Button>
      </div>
    </div>
  );
};

export default ChatBotWelcomePage;
