import { AnalysisScreenProgressText, XYPoint } from "@/types/common";
import { TransitionFn, useTransition } from "@react-spring/web";
import { ImageCardDetector } from "@scanbase/fe-algo-module";
import {
  createContext,
  MutableRefObject,
  ReactNode,
  RefObject,
  useContext,
  useRef,
  useState,
} from "react";
import useMeasure from "react-use-measure";

const config = {
  overlayColor: "#000000",
  dotSize: 6,
  dotsAnimationDuration: 1000,
  overlayAnimationDuration: 600,
  nDots: 4,
  initialScenePaddingY: 20,
};

interface IScanResultData {
  predictions: {
    [x: string]: number;
  };
}

type StripCorners = Array<XYPoint>;

interface IContext {
  overlayCanvasRef: RefObject<HTMLCanvasElement>;
  imageCanvasRef: RefObject<HTMLCanvasElement>;
  animationCanvasRef: RefObject<HTMLCanvasElement>;
  imageCardDetectorRef: MutableRefObject<ImageCardDetector | null>;
  config: typeof config;
  showDots: boolean;
  setShowDots: React.Dispatch<React.SetStateAction<boolean>>;
  showPhotoOverlay: boolean;
  setShowPhotoOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  scanResultData: IScanResultData | null;
  setScanResultData: React.Dispatch<
    React.SetStateAction<IScanResultData | null>
  >;
  stripCorners: StripCorners | null;
  setStripCorners: React.Dispatch<React.SetStateAction<StripCorners | null>>;
  stripAnchorUseMeasure: ReturnType<typeof useMeasure>;
  progressText: AnalysisScreenProgressText | null;
  setAnalysisScreenProgressText: React.Dispatch<
    React.SetStateAction<AnalysisScreenProgressText | null>
  >;
  progressTextAnimation: TransitionFn<AnalysisScreenProgressText | null, any>;
  stripViewportPositionTrackingPointRefs: MutableRefObject<
    Array<HTMLDivElement | null>
  >;
  lColorBlockGsapContextRef: MutableRefObject<{
    alpha: number;
  }>;
  lColorBlockGsapRef: MutableRefObject<gsap.core.Tween | null>;
  rColorBlockGsapContextRef: MutableRefObject<{
    alpha: number;
  }>;
  rColorBlockGsapRef: MutableRefObject<gsap.core.Tween | null>;
  roiGsapContextRef: MutableRefObject<{
    alpha: number;
  }>;
  roiGsapRef: MutableRefObject<gsap.core.Tween | null>;
}

const refDefaultValue = (value: any = null) => ({ current: value });

const defaultValue: IContext = {
  overlayCanvasRef: refDefaultValue(),
  imageCanvasRef: refDefaultValue(),
  animationCanvasRef: refDefaultValue(),
  imageCardDetectorRef: refDefaultValue(),
  config,
  showDots: false,
  setShowDots: () => {},
  showPhotoOverlay: false,
  setShowPhotoOverlay: () => {},
  showResults: false,
  setShowResults: () => {},
  scanResultData: null,
  setScanResultData: () => {},
  stripCorners: null,
  setStripCorners: () => {},
  stripAnchorUseMeasure: [] as any,
  progressText: null,
  setAnalysisScreenProgressText: () => {},
  progressTextAnimation: () => ({} as any),
  stripViewportPositionTrackingPointRefs: refDefaultValue([]),
  lColorBlockGsapContextRef: refDefaultValue({ alpha: 0 }),
  lColorBlockGsapRef: refDefaultValue(),
  rColorBlockGsapContextRef: refDefaultValue({ alpha: 0 }),
  rColorBlockGsapRef: refDefaultValue(),
  roiGsapContextRef: refDefaultValue({ alpha: 0 }),
  roiGsapRef: refDefaultValue(),
};

const AnalysisScreenContext = createContext(defaultValue);

export function AnalysisScreenContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement>(null);
  const animationCanvasRef = useRef<HTMLCanvasElement>(null);
  const imageCardDetectorRef = useRef<ImageCardDetector | null>(null);
  const [showDots, setShowDots] = useState(false);
  const [showPhotoOverlay, setShowPhotoOverlay] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [scanResultData, setScanResultData] = useState(
    defaultValue.scanResultData
  );
  const [stripCorners, setStripCorners] = useState<Array<XYPoint> | null>(
    defaultValue.stripCorners
  );
  const stripAnchorUseMeasure = useMeasure();
  const [progressText, setAnalysisScreenProgressText] =
    useState<AnalysisScreenProgressText | null>(
      AnalysisScreenProgressText.QualifyingPhoto
    );
  const stripViewportPositionTrackingPointRefs = useRef<
    Array<HTMLDivElement | null>
  >([]);
  const lColorBlockGsapContextRef = useRef({ alpha: 0 });
  const lColorBlockGsapRef = useRef<gsap.core.Tween | null>(null);
  const rColorBlockGsapContextRef = useRef({ alpha: 0 });
  const rColorBlockGsapRef = useRef<gsap.core.Tween | null>(null);
  const roiGsapContextRef = useRef({ alpha: 0 });
  const roiGsapRef = useRef<gsap.core.Tween | null>(null);

  const progressTextAnimation = useTransition(progressText, {
    from: { opacity: 0, transform: "translateY(100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(100%)" },
    exitBeforeEnter: true,
  });

  return (
    <AnalysisScreenContext.Provider
      value={{
        overlayCanvasRef,
        imageCanvasRef,
        animationCanvasRef,
        imageCardDetectorRef,
        config,
        showDots,
        setShowDots,
        showPhotoOverlay,
        setShowPhotoOverlay,
        showResults,
        setShowResults,
        scanResultData,
        setScanResultData,
        setStripCorners,
        stripCorners,
        stripAnchorUseMeasure,
        progressText,
        setAnalysisScreenProgressText,
        progressTextAnimation,
        stripViewportPositionTrackingPointRefs,
        lColorBlockGsapContextRef,
        lColorBlockGsapRef,
        rColorBlockGsapContextRef,
        rColorBlockGsapRef,
        roiGsapContextRef,
        roiGsapRef,
      }}
    >
      {children}
    </AnalysisScreenContext.Provider>
  );
}

export const useAnalysisScreenContext = () => useContext(AnalysisScreenContext);
