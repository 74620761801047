import BotImage from "@/assets/img/de1b3c7ec885da63d0fc95324d23fbcd.png";
import { useState } from "react";
import ChatContainer from "./ChatContainer";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
  };
  userId: string;
}

const Chatbot = ({ data, userId }: IProps) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <div className="fixed bottom-5 right-5 z-50">
      {!isChatOpen && (
        <div
          className="flex justify-center items-end "
          onClick={() => setIsChatOpen(true)}
        >
          <div className={`flex items-start cursor-pointer`}>
            <div
              className={`flex flex-col w-full p-3 bg-primary text-primary-foreground rounded-xl rounded-ee-none dark:bg-gray-700 mr-1`}
            >
              <p className="break-words font-normal ">
                Hi, let's talk about your results.
              </p>
              <p className="font-medium mt-1 underline">Start chat</p>
            </div>
          </div>
          <button
            className="w-14 h-14 flex items-center justify-center rounded-2xl border-[3px] border-white bg-transparent transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer"
            aria-label="Open Chat"
          >
            <img src={BotImage} alt="Chat bot icon" className="rounded-xl" />
          </button>
        </div>
      )}
      <ChatContainer
        open={isChatOpen}
        onOpenChange={setIsChatOpen}
        data={data}
        userId={userId}
      />
    </div>
  );
};

export default Chatbot;
