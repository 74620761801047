import { AuthError } from "@supabase/supabase-js";
import { isNumber, padStart } from "lodash";
import { toast } from "sonner";
import { PREFIX } from "./variables";
import { EPredictionsParameterStatus, XYPoint } from "@/types/common";
import { supabaseService } from "@/services/supabaseService";

export function handleOtpSendError(error: AuthError) {
  const { message, code } = error;
  let notificationMessage = "Error";

  switch (code) {
    case "otp_disabled":
      if (message.startsWith("Signups not allowed"))
        notificationMessage =
          "No account associated with your phone number. Please, sign up";
      break;
    case "sms_send_failed":
      if (message.includes("Invalid 'To' Phone Number"))
        notificationMessage = "Failed to send auth code to your phone number";
      break;
    default:
      break;
  }

  toast.error(notificationMessage);
  console.error(error);
}

export function getNumericEnvVariable(
  value: string | undefined,
  defaultValue = 120
): number {
  const asNumber = Number(value);
  const timerLengthInSeconds =
    isNumber(asNumber) && isFinite(asNumber) ? asNumber : defaultValue;
  return timerLengthInSeconds;
}

export function sanitizePhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, "");
}

export function formatTimerTime(timeInSeconds: number) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;
  const minutesStr = padStart(minutes.toString(), 2, "0");
  const secondsStr = padStart(seconds.toString(), 2, "0");

  return `${minutesStr}:${secondsStr}`;
}

export function getPredictionsParameterStatus(
  predictionsParameterName: string,
  value: number
) {
  const max = Math.max(PREFIX[predictionsParameterName].length - 1, 0);
  let mediumThreshold = Math.min(1, max);
  let highThreshold = max;

  switch (predictionsParameterName) {
    case "sg":
      mediumThreshold = max;
      break;
    case "ph":
      highThreshold = max + 1;
      mediumThreshold = highThreshold;
      break;
    case "uro":
      mediumThreshold = 3;
      break;
    case "bil":
      mediumThreshold = 2;
      break;
    default:
      break;
  }

  const status =
    value >= highThreshold
      ? EPredictionsParameterStatus.High
      : value >= mediumThreshold && value < highThreshold
      ? EPredictionsParameterStatus.Medium
      : EPredictionsParameterStatus.Normal;
  return status;
}

export const getCurrentUserLegalSex = async () => {
  const {
    data: { user },
  } = await supabaseService.getClient().auth.getUser();
  if (typeof localStorage === "undefined" || localStorage === null) {
    return undefined;
  }

  const stroredData = localStorage.getItem("user_legal_sex");

  if (user?.user_metadata?.legal_sex) {
    return user.user_metadata.legal_sex;
  } else if (stroredData) {
    return stroredData;
  } else {
    return undefined;
  }
};

export const vectorDistance = (p1: XYPoint, p2: XYPoint) => {
  const a = p1.x - p2.x;
  const b = p1.y - p2.y;
  const c = Math.sqrt(a * a + b * b);
  return c;
};

export function hexToRgb(hex: string) {
  // Remove the '#' if it exists
  hex = hex.replace("#", "");

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse hex values into decimal RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
}

export function angle360(p1: XYPoint, p2: XYPoint) {
  var dy = p2.y - p1.y;
  var dx = p2.x - p1.x;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta;
}

export function hexToRgbAlpha(hex: string, alpha: number) {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const pt = (x: number, y: number) => ({ x, y });
