// Linear Algebra utilities

var LA = LA || {};
this.LA = LA;

LA.mean = function (matrix) {
  const sum = matrix.reduce(
    (acc, row) => [acc[0] + row[0], acc[1] + row[1]],
    [0, 0]
  );
  return [sum[0] / matrix.length, sum[1] / matrix.length];
};

LA.subtract = function (matrix, vector) {
  return matrix.map((row) => [row[0] - vector[0], row[1] - vector[1]]);
};

LA.covariance = function (matrix) {
  const n = matrix.length;
  let xx = 0,
    xy = 0,
    yy = 0;

  for (const row of matrix) {
    xx += row[0] * row[0];
    xy += row[0] * row[1];
    yy += row[1] * row[1];
  }

  xx /= n;
  xy /= n;
  yy /= n;

  return [
    [xx, xy],
    [xy, yy],
  ];
};

LA.eigenDecomposition = function (matrix) {
  const a = matrix[0][0];
  const b = matrix[0][1];
  const c = matrix[1][1];

  const trace = a + c;
  const det = a * c - b * b;
  const discriminant = Math.sqrt(trace * trace - 4 * det);

  const eigenval1 = (trace + discriminant) / 2;
  const eigenval2 = (trace - discriminant) / 2;

  const eigenvec1 = [b, eigenval1 - a];
  const eigenvec2 = [b, eigenval2 - a];

  // Normalize eigenvectors
  const norm1 = Math.sqrt(
    eigenvec1[0] * eigenvec1[0] + eigenvec1[1] * eigenvec1[1]
  );
  const norm2 = Math.sqrt(
    eigenvec2[0] * eigenvec2[0] + eigenvec2[1] * eigenvec2[1]
  );

  eigenvec1[0] /= norm1;
  eigenvec1[1] /= norm1;
  eigenvec2[0] /= norm2;
  eigenvec2[1] /= norm2;

  return {
    eigenvals: [eigenval1, eigenval2],
    eigenvecs: [eigenvec1, eigenvec2],
  };
};

LA.dot = function (matrix, vector) {
  return matrix.map((row) => row[0] * vector[0] + row[1] * vector[1]);
};

LA.median = function (arr) {
  const sorted = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
};

LA.createBins = function (min, max, numBins) {
  return new Array(numBins)
    .fill(0)
    .map((_, i) => min + ((max - min) * i) / (numBins - 1));
};

LA.getBinIndices = function (values, bins) {
  return values.map((v) => bins.findIndex((b) => v <= b));
};

LA.getProjectionExtrema = function (proj, binIndices, numBins, minPoints) {
  const extrema = [];
  for (let binIdx = 1; binIdx < numBins; binIdx++) {
    const binPoints = proj.filter((_, i) => binIndices[i] === binIdx);
    if (binPoints.length > minPoints) {
      extrema.push([Math.min(...binPoints), Math.max(...binPoints)]);
    }
  }
  return extrema;
};

LA.calculateVectors = function (
  majorAxis,
  minorAxis,
  majorProjMin,
  majorProjMax,
  minorProjMin,
  minorProjMax
) {
  return {
    vertical: [
      [majorProjMin * majorAxis[0], majorProjMin * majorAxis[1]],
      [majorProjMax * majorAxis[0], majorProjMax * majorAxis[1]],
    ],
    horizontal: [
      [minorProjMin * minorAxis[0], minorProjMin * minorAxis[1]],
      [minorProjMax * minorAxis[0], minorProjMax * minorAxis[1]],
    ],
  };
};

LA.calculateCorners = function (meanPoint, upVec, downVec, leftVec, rightVec) {
  const top = [meanPoint[0] + upVec[0], meanPoint[1] + upVec[1]];
  const bottom = [meanPoint[0] + downVec[0], meanPoint[1] + downVec[1]];

  return [
    [top[1] + leftVec[1], top[0] + leftVec[0]],
    [top[1] + rightVec[1], top[0] + rightVec[0]],
    [bottom[1] + rightVec[1], bottom[0] + rightVec[0]],
    [bottom[1] + leftVec[1], bottom[0] + leftVec[0]],
  ];
};

LA.analyzeOrientation = function (corners, intensity, stripParams) {
  const peaks = findPeaks(intensity);
  const a0 =
    stripParams.reagentCellLocations.at(-1) +
    stripParams.reagentCellSize.height;
  const a = (a0 / stripParams.stripSize.height) * intensity.length;

  const peakCounts = peaks.reduce(
    (acc, peak) => ({
      top: acc.top + (peak < a ? 1 : 0),
      bottom: acc.bottom + (peak > intensity.length - a ? 1 : 0),
    }),
    { top: 0, bottom: 0 }
  );

  if (peakCounts.top < peakCounts.bottom) {
    [corners[0], corners[1], corners[2], corners[3]] = [
      corners[3],
      corners[0],
      corners[1],
      corners[2],
    ];
  }

  return corners;
};

LA.calculateIntensity = function (gray, yx, binIndices, numBins) {
  const intensity = new Array(numBins - 1).fill(0);
  const binCounts = new Array(numBins - 1).fill(0);

  for (let i = 0; i < yx.length; i++) {
    const binIdx = binIndices[i];
    if (binIdx > 0 && binIdx < numBins) {
      const [y, x] = yx[i];
      intensity[binIdx - 1] += gray.data[y * gray.width + x];
      binCounts[binIdx - 1]++;
    }
  }

  return intensity.map((sum, i) => (binCounts[i] > 0 ? sum / binCounts[i] : 0));
};

LA.findPeaks = function (arr, options = {}) {
  // Default options
  const {
    height = -Infinity, // Minimum peak height
    threshold = 0, // Required height difference to neighbors
    distance = 1, // Minimum distance between peaks
    prominence = 0, // Minimum prominence of peaks
  } = options;

  const peaks = [];
  const peakIndices = [];

  // Need at least 3 points to find a peak
  if (arr.length < 3) return { peaks, peakIndices };

  // Helper function to calculate prominence
  function calculateProminence(index) {
    const peakHeight = arr[index];
    let leftMin = peakHeight;
    let rightMin = peakHeight;

    // Look left for higher peak or lowest point
    for (let i = index - 1; i >= 0; i--) {
      if (arr[i] > peakHeight) return 0;
      leftMin = Math.min(leftMin, arr[i]);
    }

    // Look right for higher peak or lowest point
    for (let i = index + 1; i < arr.length; i++) {
      if (arr[i] > peakHeight) return 0;
      rightMin = Math.min(rightMin, arr[i]);
    }

    return peakHeight - Math.max(leftMin, rightMin);
  }

  // Find all potential peaks
  for (let i = 1; i < arr.length - 1; i++) {
    const current = arr[i];
    const prev = arr[i - 1];
    const next = arr[i + 1];

    // Check if point is a local maximum
    if (current > prev && current > next) {
      // Check height requirement
      if (current >= height) {
        // Check threshold requirement
        if (current - prev >= threshold && current - next >= threshold) {
          // Check prominence requirement
          const peakProminence = calculateProminence(i);
          if (peakProminence >= prominence) {
            peaks.push(current);
            peakIndices.push(i);
          }
        }
      }
    }
  }

  // Filter peaks by distance
  if (distance > 1) {
    const filteredPeaks = [];
    const filteredIndices = [];
    let lastPeakIndex = -distance;

    for (let i = 0; i < peakIndices.length; i++) {
      if (peakIndices[i] - lastPeakIndex >= distance) {
        filteredPeaks.push(peaks[i]);
        filteredIndices.push(peakIndices[i]);
        lastPeakIndex = peakIndices[i];
      }
    }

    return { peaks: filteredPeaks, peakIndices: filteredIndices };
  }

  return { peaks, peakIndices };
};

LA.applyTransformMatrix = function (transformMatrix, points) {
  // Validate inputs
  if (transformMatrix.length !== 9) {
    throw new Error("Transform matrix must have exactly 9 elements");
  }

  return points.map(([x, y]) => {
    // For 2D points, we assume z=1 to handle translation
    const transformedX =
      transformMatrix[0] * x + transformMatrix[1] * y + transformMatrix[2];
    const transformedY =
      transformMatrix[3] * x + transformMatrix[4] * y + transformMatrix[5];
    const w =
      transformMatrix[6] * x + transformMatrix[7] * y + transformMatrix[8];

    // Apply perspective division if w != 1
    if (Math.abs(w - 1) > Number.EPSILON) {
      return [transformedX / w, transformedY / w];
    }

    return [transformedX, transformedY];
  });
};

LA.getDistance = function (point1, point2) {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
  );
};

LA.areNumbersClose = function (num1, num2, minRatio) {
  const min = Math.min(num1, num2);
  const max = Math.max(num1, num2);
  return min / max >= minRatio;
};

LA.calculateQuadrilateralArea = function (points) {
  // Check if we have exactly 4 points
  if (points.length !== 4) {
    throw new Error("A quadrilateral must have exactly 4 points");
  }

  // Using the Shoelace formula (also known as surveyor's formula)
  // Area = 1/2 * |x1(y2 - yn) + x2(y3 - y1) + x3(y4 - y2) + x4(y1 - y3)|
  let area = 0;

  for (let i = 0; i < 4; i++) {
    const currentPoint = points[i];
    const nextPoint = points[(i + 1) % 4];
    area += currentPoint.x * nextPoint.y - nextPoint.x * currentPoint.y;
  }

  // Take the absolute value and divide by 2
  return Math.abs(area) / 2;
};

LA.inverseMatrix = function(matrix) {
  // Check input validity
  if (!Array.isArray(matrix) || matrix.length !== 9) {
    throw new Error("Input must be an array of 9 numbers");
  }

  // Calculate the determinant
  const det =
    matrix[0] * (matrix[4] * matrix[8] - matrix[5] * matrix[7]) -
    matrix[1] * (matrix[3] * matrix[8] - matrix[5] * matrix[6]) +
    matrix[2] * (matrix[3] * matrix[7] - matrix[4] * matrix[6]);

  // Check if matrix is invertible
  if (Math.abs(det) < 1e-10) {
    return null; // Matrix is not invertible
  }

  // Calculate cofactor matrix
  const cofactors = [
    // First row
    matrix[4] * matrix[8] - matrix[5] * matrix[7],
    -(matrix[3] * matrix[8] - matrix[5] * matrix[6]),
    matrix[3] * matrix[7] - matrix[4] * matrix[6],
    // Second row
    -(matrix[1] * matrix[8] - matrix[2] * matrix[7]),
    matrix[0] * matrix[8] - matrix[2] * matrix[6],
    -(matrix[0] * matrix[7] - matrix[1] * matrix[6]),
    // Third row
    matrix[1] * matrix[5] - matrix[2] * matrix[4],
    -(matrix[0] * matrix[5] - matrix[2] * matrix[3]),
    matrix[0] * matrix[4] - matrix[1] * matrix[3],
  ];

  // Calculate adjugate (transpose of cofactor matrix)
  const adjugate = [
    cofactors[0],
    cofactors[3],
    cofactors[6],
    cofactors[1],
    cofactors[4],
    cofactors[7],
    cofactors[2],
    cofactors[5],
    cofactors[8],
  ];

  // Calculate inverse by dividing adjugate by determinant
  return adjugate.map((x) => x / det);
}
