var CFG = CFG || {};
this.CFG = CFG;

// ToDo: use API to get the card configs
CFG.cardConfigs = {
  cPB5G6GYP: {
    aruco: 2,
    corner_marker_ids: {
      top_left: 0,
      top_right: 1,
      bottom_left: 2,
      bottom_right: 3,
    },
    scale: 48,
    marker_size: {
      width: 2,
      height: 2,
    },
    card_size: {
      width: 14,
      height: 29,
    },
    strip_roi: {
      bbox: {
        top: 0,
        left: 4,
        bottom: 29,
        right: 10,
      },
      roi_trim: {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
      },
    },
    color_grid_params: {
      cell_size: {
        width: 1,
        height: 1,
      },
      y_start: 3,
      num_rows: 22,
      left_block: {
        num_columns: 3,
        x_start: 0,
      },
      right_block: {
        num_columns: 3,
        x_start: 11,
      },
      color_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
    },
    strip: {
      strip_size: {
        width: 50,
        height: 1075,
      },
      analytes: [
        "leu",
        "nit",
        "uro",
        "pro",
        "ph",
        "blo",
        "sg",
        "ket",
        "bil",
        "glu",
      ],
      reagent_cell_size: {
        width: 50,
        height: 50,
      },
      reagent_cell_locations: [0, 75, 150, 225, 300, 375, 450, 525, 600, 675],
      reagent_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
      strip_id: "s94FBD8CK",
    },
  },
  cQXY72DVA: {
    aruco: 2,
    corner_marker_ids: {
      top_left: 0,
      top_right: 15,
      bottom_left: 2,
      bottom_right: 3,
    },
    scale: 48,
    marker_size: {
      width: 2,
      height: 2,
    },
    card_size: {
      width: 14,
      height: 29,
    },
    strip_roi: {
      bbox: {
        top: 0,
        left: 4,
        bottom: 29,
        right: 10,
      },
      roi_trim: {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
      },
    },
    color_grid_params: {
      cell_size: {
        width: 1,
        height: 1,
      },
      y_start: 3,
      num_rows: 23,
      left_block: {
        num_columns: 3,
        x_start: 0,
      },
      right_block: {
        num_columns: 3,
        x_start: 11,
      },
      color_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
    },
    strip: {
      strip_size: {
        width: 50,
        height: 1075,
      },
      analytes: [
        "leu",
        "nit",
        "uro",
        "pro",
        "ph",
        "blo",
        "sg",
        "ket",
        "bil",
        "glu",
      ],
      reagent_cell_size: {
        width: 50,
        height: 50,
      },
      reagent_cell_locations: [0, 75, 150, 225, 300, 375, 450, 525, 600, 675],
      reagent_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
      strip_id: "s94FBD8CK",
    },
  },
  c7B48F7EH: {
    aruco: 2,
    corner_marker_ids: {
      top_left: 0,
      top_right: 18,
      bottom_left: 2,
      bottom_right: 3,
    },
    scale: 48,
    marker_size: {
      width: 4,
      height: 4,
    },
    card_size: {
      width: 18,
      height: 36,
    },
    strip_roi: {
      bbox: {
        top: 0,
        left: 5,
        bottom: 36,
        right: 13,
      },
      roi_trim: {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
      },
    },
    color_grid_params: {
      cell_size: {
        width: 1,
        height: 1,
      },
      y_start: 7,
      num_rows: 22,
      left_block: {
        num_columns: 4,
        x_start: 0,
      },
      right_block: {
        num_columns: 4,
        x_start: 14,
      },
      color_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
    },
    strip: {
      strip_size: {
        width: 50,
        height: 1065,
      },
      analytes: [
        "leu",
        "nit",
        "uro",
        "pro",
        "ph",
        "blo",
        "sg",
        "ket",
        "bil",
        "glu",
      ],
      reagent_cell_size: {
        width: 50,
        height: 50,
      },
      reagent_cell_locations: [0, 80, 155, 230, 310, 385, 460, 540, 615, 690],
      reagent_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
      strip_id: "sZBY4NAQQ",
    },
  },
  cDJ39XM5N: {
    aruco: 2,
    corner_marker_ids: {
      top_left: 0,
      top_right: 20,
      bottom_left: 2,
      bottom_right: 3,
    },
    scale: 48,
    marker_size: {
      width: 4,
      height: 4,
    },
    card_size: {
      width: 18,
      height: 36,
    },
    strip_roi: {
      bbox: {
        top: 0,
        left: 5,
        bottom: 36,
        right: 13,
      },
      roi_trim: {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
      },
    },
    color_grid_params: {
      cell_size: {
        width: 1,
        height: 1,
      },
      y_start: 5,
      num_rows: 26,
      left_block: {
        num_columns: 4,
        x_start: 0,
      },
      right_block: {
        num_columns: 4,
        x_start: 14,
      },
      color_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
    },
    strip: {
      strip_size: {
        width: 50,
        height: 1065,
      },
      analytes: [
        "leu",
        "nit",
        "uro",
        "pro",
        "ph",
        "blo",
        "sg",
        "ket",
        "bil",
        "glu",
      ],
      reagent_cell_size: {
        width: 50,
        height: 50,
      },
      reagent_cell_locations: [0, 80, 155, 230, 310, 385, 460, 540, 615, 690],
      reagent_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
      strip_id: "sZBY4NAQQ",
    },
  },
  cPNPETY8D: {
    aruco: 2,
    corner_marker_ids: {
      top_left: 0,
      top_right: 21,
      bottom_left: 2,
      bottom_right: 3,
    },
    scale: 48,
    marker_size: {
      width: 4,
      height: 4,
    },
    card_size: {
      width: 18,
      height: 36,
    },
    strip_roi: {
      bbox: {
        top: 0,
        left: 5,
        bottom: 36,
        right: 13,
      },
      roi_trim: {
        top: 6,
        left: 6,
        bottom: 6,
        right: 6,
      },
    },
    color_grid_params: {
      cell_size: {
        width: 1,
        height: 1,
      },
      y_start: 5,
      num_rows: 26,
      left_block: {
        num_columns: 4,
        x_start: 0,
      },
      right_block: {
        num_columns: 4,
        x_start: 14,
      },
      color_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
    },
    strip: {
      strip_size: {
        width: 50,
        height: 1065,
      },
      analytes: [
        "leu",
        "nit",
        "uro",
        "pro",
        "ph",
        "blo",
        "sg",
        "ket",
        "bil",
        "glu",
      ],
      reagent_cell_size: {
        width: 50,
        height: 50,
      },
      reagent_cell_locations: [0, 80, 155, 230, 310, 385, 460, 540, 615, 690],
      reagent_cell_trim: {
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      },
      strip_id: "sZBY4NAQQ",
    },
  },
};

CFG.findCardConfigByMarkers = function (configs, ...markerIds) {
  // Validate input
  if (markerIds.length !== 4) {
    throw new Error("Exactly 4 marker IDs must be provided");
  }

  // Convert input marker IDs to a Set for comparison
  const inputMarkerSet = new Set(markerIds);

  // Convert the configs object into an array of entries
  const entries = Object.entries(configs);

  // Find the matching configuration
  const matchingEntry = entries.find(([_, config]) => {
    const configMarkers = Object.values(config.corner_marker_ids);
    const configMarkerSet = new Set(configMarkers);

    // Compare sets - they should have same size and same elements
    if (inputMarkerSet.size !== configMarkerSet.size) {
      return false;
    }

    // Check if all input markers exist in the config markers
    return Array.from(inputMarkerSet).every((marker) =>
      configMarkerSet.has(marker)
    );
  });

  // Return the matching config or null if not found
  return matchingEntry ? matchingEntry[1] : null;
};
