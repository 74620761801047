import { messagesAtom } from "@/store/atoms/messageAtom";
import { showInputBox } from "@/store/atoms/userAtom";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { Drawer, DrawerContent, DrawerTitle } from "../ui/drawer";
import ChatBubble from "./ChatBubble";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ChatBotWelcomePage from "./WelcomePage";

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: {
    predictions: {
      [key: string]: number;
    };
  };
  userId: string;
}

const ChatContainer = ({ open, onOpenChange, data, userId }: IProps) => {
  const [startConversation, setStartConversation] = useState(false);
  const [showInputBoxFlag] = useAtom(showInputBox);
  const [messages] = useAtom(messagesAtom);

  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const div = chatContainerRef.current;

    if (div) {
      div.scrollTo({
        top: div.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className="h-[90%] pb-5">
        <div className="w-full max-w-screen-sm mx-auto px-5 pb-5">
          <DrawerTitle className="text-center font-bold">
            <ChatHeader isChatOpen={open} setIsChatOpen={onOpenChange} />
          </DrawerTitle>
        </div>
        {startConversation || showInputBoxFlag ? (
          <>
            <div ref={chatContainerRef} className="p-4 overflow-y-auto">
              <ChatBubble data={data} userId={userId} />
            </div>
            {showInputBoxFlag && <ChatFooter data={data} userId={userId} />}
          </>
        ) : (
          <ChatBotWelcomePage setStartConversation={setStartConversation} />
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ChatContainer;
