import ResultRow from "@/components/AnalysisScreen/ResultRow";
import { useAnalysisScreenContext } from "@/contexts/analysisScreenContext";
import { animated } from "@react-spring/web";
import { CSSProperties, useCallback } from "react";

interface IProps {
  onResultRowAnimationRest: (rowIndex: number) => any;
}

export default function ResultsScene({
  onResultRowAnimationRest = () => {},
}: IProps) {
  const {
    showResults,
    scanResultData,
    stripAnchorUseMeasure,
    progressTextAnimation,
    imageCardDetectorRef,
  } = useAnalysisScreenContext();
  const [stripAnchorRef, stripAnchorMeasure] = stripAnchorUseMeasure;

  const getResultRowStyles = useCallback(
    (index: number): CSSProperties => {
      const cardConfig = imageCardDetectorRef.current?.cardConfig;
      if (!cardConfig) return {};
      const configStripWidth = cardConfig.strip.strip_size.width;
      const viewportStripWidth = stripAnchorMeasure.width;
      const scale = configStripWidth / viewportStripWidth;

      let totalGap = 0;
      for (let i = 0; i < index; i++) {
        totalGap +=
          cardConfig.strip.reagent_cell_locations[i + 1] -
          cardConfig.strip.reagent_cell_locations[i] -
          cardConfig.strip.reagent_cell_size.height;
      }

      const top = cardConfig.strip.reagent_cell_locations[index] * scale;

      return {
        position: "absolute",
        top: `${top - totalGap}px`,
      };
    },
    [imageCardDetectorRef, stripAnchorMeasure.width]
  );

  return (
    <div
      id="result-scene"
      className="absolute top-0 left-0 w-full h-full z-[40]"
      style={{ opacity: showResults ? 1 : 0 }}
    >
      <div className="max-w-screen-sm mx-auto w-full py-6 px-5">
        <div className="h-[50px] flex flex-col justify-center items-center">
          {progressTextAnimation((style, text, state, index) => {
            return (
              <animated.div style={style}>
                {text && (
                  <div className="text-white flex items-center justify-center font-semibold text-xl gap-3">
                    {text}
                  </div>
                )}
              </animated.div>
            );
          })}
        </div>
        <div className="relative">
          <div className="grid grid-cols-analysis-screen-results h-0">
            <div></div>
            <div id="result-scene-strip-anchor" ref={stripAnchorRef}></div>
          </div>
          {scanResultData?.predictions &&
            Object.entries(scanResultData.predictions).map(
              ([key, value], index) => {
                return (
                  <div
                    style={getResultRowStyles(index)}
                    key={index}
                    className="w-full"
                  >
                    <ResultRow
                      prediction={{ key, value: value as number }}
                      show={showResults}
                      index={index}
                      onAnimationRest={onResultRowAnimationRest}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}
