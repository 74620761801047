import { IIconProps } from "@/types/common";

export default function CheckCircle({ className }: IIconProps) {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="17.5" cy="18" r="16" stroke="currentColor" strokeWidth="3" />
      <path d="M9.5 17L15.5 23L25.5 13" stroke="currentColor" strokeWidth="3" />
    </svg>
  );
}
