import { Drawer, DrawerContent, DrawerTitle } from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import CheckCircle from "../icons/CheckCircle";

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onViewResults: () => void;
}

export default function AnalysisCompleteModal({
  open,
  onOpenChange,
  onViewResults,
}: IProps) {
  return (
    <Drawer
      open={open}
      onOpenChange={onOpenChange}
      modal={false}
      dismissible={false}
    >
      <DrawerContent overlayClassName="hidden pointer-events-none">
        <div className="w-full max-w-screen-sm mx-auto px-5 py-5">
          <DrawerTitle asChild className="text-2xl mb-4">
            <h2 className="text-center font-semibold mb-3 flex flex-row items-center gap-4 justify-center">
              <span>Analysis Complete</span>
              <CheckCircle className="text-ribbon-cobalt-700" />
            </h2>
          </DrawerTitle>
          <Button onClick={onViewResults} className="w-full space-x-2">
            <span>View Results</span>
            <ArrowRight />
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
