const AR = require("./aruco").AR;
const LA = require("./matrix").LA;
const CV = require("./cv").CV;
const CFG = require("./config").CFG;

class ImageCardDetector {
  constructor() {
    this.cardImage = new CV.Image();
    this.status = null;
    this.detector = new AR.Detector({ dictionaryName: "ARUCO_4X4_1000" });
    this.preProcessScale = null;
    this.transformMatrix = null;
    this.orderedCorners = null;
    this.roiPoints = null;
    this.leftColorBlockCells = null;
    this.rightColorBlockCells = null;
    this.markerCorners = null;
    this.cardConfig = null;
  }

  processImage(imageData) {
    this.preProcessScale = 720 / Math.max(imageData.width, imageData.height);
    imageData = CV.resizeImageData(imageData, this.preProcessScale);
    if (this.extractCard(imageData)) {
      if (this.isValidStrip()) {
        return true;
      }
    }
    return false;
  }

  extractCard(imageData) {
    const allMarkers = this.detector.detect(imageData);

    // Group markers by ID and select highest confidence marker for each ID
    const markerGroups = new Map();
    allMarkers.forEach((marker) => {
      const currentBest = markerGroups.get(marker.id);
      if (!currentBest || marker.confidence > currentBest.confidence) {
        markerGroups.set(marker.id, marker);
      }
    });

    const markers = Array.from(markerGroups.values()).filter((marker) =>
      marker.hasEqualSides(0.3)
    );

    if (markers.length < 4) {
      this.status = `Status: Not enough markers in view (found ${markers.length})`;
      return false;
    } else if (markers.length > 4) {
      this.status = `Status: Too many markers in view (found ${markers.length})`;
      return false;
    } else {
      this.setCardParams(markers);
      this.initMarkerCorners(markers);
      const orderedCorners = this.getOrderedCornersByID(markers);
      this.orderedCorners = orderedCorners.map((corner) => ({
        x: corner.x / this.preProcessScale,
        y: corner.y / this.preProcessScale,
      }));
      const srcPoints = orderedCorners.map((corner) => [corner.x, corner.y]);
      const sourcePoints = srcPoints.map((point) => ({
        x: point[0],
        y: point[1],
      }));
      if (!this.isValidCameraAngle(sourcePoints)) {
        this.status = "Status: Camera angle too steep";
        return false;
      }
      if (
        !this.isValidCameraDistance(
          sourcePoints,
          imageData.width,
          imageData.height
        )
      ) {
        this.status = "Status: Camera too far from the card";
        return false;
      }

      this.transformMatrix = CV.getInvPerspTransform(
        sourcePoints,
        this.cardWidth,
        this.cardHeight
      );
      const tgtPoints = [
        [0, 0],
        [this.cardWidth, 0],
        [this.cardWidth, this.cardHeight],
        [0, this.cardHeight],
      ];

      CV.warpColor(
        CV.Image.fromImageData(imageData),
        this.cardImage,
        sourcePoints,
        this.cardWidth,
        this.cardHeight
      );
      this.initRoiPoints();
      this.initColorBlocks();

      if (!this.isValidSharpness()) {
        this.status = "Status: Image not sharp enough";
        return false;
      }

      this.status = "Status: Card detected";
      return true;
    }
  }

  setCardParams(markers) {
    const cardConfig = CFG.findCardConfigByMarkers(
      CFG.cardConfigs,
      ...markers.map((m) => m.id)
    );
    this.cardConfig = cardConfig;
    const cornerOrder = [
      "top_left",
      "top_right",
      "bottom_right",
      "bottom_left",
    ];
    this.markerIds = cornerOrder.map(
      (corner) => cardConfig.corner_marker_ids[corner]
    );
    this.colorGridParams = {
      cellSize: {
        width: cardConfig.color_grid_params.cell_size.width,
        height: cardConfig.color_grid_params.cell_size.height,
      },
      yStart: cardConfig.color_grid_params.y_start,
      numRows: cardConfig.color_grid_params.num_rows,
      leftBlock: {
        numColumns: cardConfig.color_grid_params.left_block.num_columns,
        xStart: cardConfig.color_grid_params.left_block.x_start,
      },
      rightBlock: {
        numColumns: cardConfig.color_grid_params.right_block.num_columns,
        xStart: cardConfig.color_grid_params.right_block.x_start,
      },
    };
    this.scale = 32; // cardConfig.scale;
    this.cardWidth = cardConfig.card_size.width * this.scale;
    this.cardHeight = cardConfig.card_size.height * this.scale;
    this.markerSize = {
      width: cardConfig.marker_size.width,
      height: cardConfig.marker_size.height,
    };
    this.roiBbox = {
      top: cardConfig.strip_roi.bbox.top,
      left: cardConfig.strip_roi.bbox.left,
      bottom: cardConfig.strip_roi.bbox.bottom,
      right: cardConfig.strip_roi.bbox.right,
    };
    // ToDo: Remove this hardcoding, fix on backend
    this.roiTrim = { top: 0.54, left: 0.54, bottom: 0.54, right: 0.54 };
    this.stripSize = {
      width: cardConfig.strip.strip_size.width,
      height: cardConfig.strip.strip_size.height,
    };
    this.padLocations = cardConfig.strip.reagent_cell_locations.map(
      (y) => y + cardConfig.strip.reagent_cell_size.height / 2
    );

    // this.roiTrim = {
    //   top: cardConfig.strip_roi.roi_trim.top,
    //   left: cardConfig.strip_roi.roi_trim.left,
    //   bottom: cardConfig.strip_roi.roi_trim.bottom,
    //   right: cardConfig.strip_roi.roi_trim.right,
    // };
  }

  getOrderedCornersByID(markers) {
    const markerMap = new Map(markers.map((m) => [m.id, m]));
    const corners = [];

    this.markerIds.forEach((id, i) => {
      if (markerMap.has(id)) {
        corners.push(markerMap.get(id).corners[i]);
      }
    });

    return corners;
  }

  isValidCameraAngle(corners) {
    const sides = [
      LA.getDistance(corners[0], corners[1]),
      LA.getDistance(corners[1], corners[2]),
      LA.getDistance(corners[2], corners[3]),
      LA.getDistance(corners[3], corners[0]),
    ];
    return (
      LA.areNumbersClose(sides[0], sides[2], 0.5) &&
      LA.areNumbersClose(sides[1], sides[3], 0.5)
    );
  }

  isValidCameraDistance(corners, imageWidth, imageHeight) {
    const area = LA.calculateQuadrilateralArea(corners);
    const imageArea = imageWidth * imageHeight;
    return area / imageArea > 0.05;
  }

  isValidSharpness() {
    let grey = new CV.Image();
    CV.grayscale(this.cardImage, grey);

    const padding = Math.round(
      0.3 * this.colorGridParams.cellSize.width * this.scale
    );
    const markerSize = Math.round(this.markerSize.width * this.scale) + padding;

    // Extract crops of markers at the four corners
    const markerCrops = [];
    markerCrops.push(grey.crop(padding, padding, markerSize, markerSize));
    markerCrops.push(
      grey.crop(
        this.cardWidth - markerSize,
        padding,
        this.cardWidth - padding,
        markerSize
      )
    );
    markerCrops.push(
      grey.crop(
        this.cardWidth - markerSize,
        this.cardHeight - markerSize,
        this.cardWidth - padding,
        this.cardHeight - padding
      )
    );
    markerCrops.push(
      grey.crop(
        padding,
        this.cardHeight - markerSize,
        markerSize + padding,
        this.cardHeight - padding
      )
    );

    const blur = Math.min(
      ...markerCrops.map((section) => section.laplacianVariance())
    );
    if (blur < 12) {
      return false;
    }
    return true;
  }

  isValidStrip() {
    let grey = new CV.Image();
    let binaryImage = new CV.Image();
    const roiBboxLeft = (this.roiBbox.left + this.roiTrim.left) * this.scale;
    const roiBboxTop = (this.roiBbox.top + this.roiTrim.top) * this.scale;
    const roiBboxRight = (this.roiBbox.right - this.roiTrim.right) * this.scale;
    const roiBboxBottom =
      (this.roiBbox.bottom - this.roiTrim.bottom) * this.scale;
    // const roiBboxWidth = roiBboxRight - roiBboxLeft;
    // const roiBboxHeight = roiBboxBottom - roiBboxTop;
    const roiImage = this.cardImage.crop(
      Math.round(roiBboxLeft),
      Math.round(roiBboxTop),
      Math.round(roiBboxRight),
      Math.round(roiBboxBottom)
    );
    CV.grayscale(roiImage, grey);
    const threshold = Math.max(CV.otsu(grey), 50);
    CV.threshold(grey, binaryImage, threshold);
    const yx = CV.argwhere(binaryImage);
    const area = yx.length / (grey.width * grey.height);
    if (area < 0.15) {
      this.status = "Status: Strip missing or outside bounds";
      return false;
    } else if (area > 0.19) {
      this.status = "Status: Area around strip possibly contaminated";
      return false;
    }
    return true;
  }

  initMarkerCorners(markers) {
    this.markerCorners = {};
    markers.forEach((marker) => {
      const corners = marker.corners.map((corner) => ({
        x: corner.x / this.preProcessScale,
        y: corner.y / this.preProcessScale,
      }));
      this.markerCorners[marker.id] = corners;
    });
  }

  initRoiPoints() {
    const roiBbox = this.roiBbox;
    let roiPoints = [
      [roiBbox.left, roiBbox.top],
      [roiBbox.right, roiBbox.top],
      [roiBbox.right, roiBbox.bottom],
      [roiBbox.left, roiBbox.bottom],
    ];
    roiPoints = roiPoints.map((p) => [p[0] * this.scale, p[1] * this.scale]);
    roiPoints = LA.applyTransformMatrix(this.transformMatrix, roiPoints);
    roiPoints = roiPoints.reduce((a, b) => {
      const [x, y] = b;
      return [...a, { x, y }];
    }, []);
    this.roiPoints = roiPoints.map((p) => ({
      x: p.x / this.preProcessScale,
      y: p.y / this.preProcessScale,
    }));
  }

  initColorBlocks() {
    const { cellSize, yStart, numRows, leftBlock, rightBlock } =
      this.colorGridParams;

    const leftBlockPoints = [];
    const rightBlockPoints = [];
    for (let i = 0; i <= numRows; i++) {
      for (let j = 0; j <= leftBlock.numColumns; j++) {
        leftBlockPoints.push([
          (leftBlock.xStart + j * cellSize.width) * this.scale,
          (yStart + i * cellSize.height) * this.scale,
        ]);
      }
      for (let j = 0; j <= rightBlock.numColumns; j++) {
        rightBlockPoints.push([
          (rightBlock.xStart + j * cellSize.width) * this.scale,
          (yStart + i * cellSize.width) * this.scale,
        ]);
      }
    }

    const leftBlockPointsTransformed = LA.applyTransformMatrix(
      this.transformMatrix,
      leftBlockPoints
    );
    const rightBlockPointsTransformed = LA.applyTransformMatrix(
      this.transformMatrix,
      rightBlockPoints
    );
    this.leftColorBlockCells = [];
    this.rightColorBlockCells = [];

    const a = leftBlock.numColumns + 1;
    for (let i = 0; i < numRows; i++) {
      for (let j = 0; j < leftBlock.numColumns; j++) {
        let corners = [
          leftBlockPointsTransformed[i * a + j],
          leftBlockPointsTransformed[i * a + j + 1],
          leftBlockPointsTransformed[(i + 1) * a + j + 1],
          leftBlockPointsTransformed[(i + 1) * a + j],
        ].map(([x, y]) => [x / this.preProcessScale, y / this.preProcessScale]);
        this.leftColorBlockCells.push({ corners, rowIndex: i, colIndex: j });
      }
    }

    const b = rightBlock.numColumns + 1;
    for (let i = 0; i < numRows; i++) {
      for (let j = 0; j < rightBlock.numColumns; j++) {
        const corners = [
          rightBlockPointsTransformed[i * b + j],
          rightBlockPointsTransformed[i * b + j + 1],
          rightBlockPointsTransformed[(i + 1) * b + j + 1],
          rightBlockPointsTransformed[(i + 1) * b + j],
        ].map(([x, y]) => [x / this.preProcessScale, y / this.preProcessScale]);
        this.rightColorBlockCells.push({ corners, rowIndex: i, colIndex: j });
      }
    }
  }

  extractStrip() {
    let grey = new CV.Image();
    let binaryImage = new CV.Image();
    const roiBboxLeft = (this.roiBbox.left + this.roiTrim.left) * this.scale;
    const roiBboxTop = (this.roiBbox.top + this.roiTrim.top) * this.scale;
    const roiBboxRight = (this.roiBbox.right - this.roiTrim.right) * this.scale;
    const roiBboxBottom =
      (this.roiBbox.bottom - this.roiTrim.bottom) * this.scale;
    const roiImage = this.cardImage.crop(
      roiBboxLeft,
      roiBboxTop,
      roiBboxRight,
      roiBboxBottom
    );
    CV.grayscale(roiImage, grey);
    CV.threshold(grey, binaryImage, CV.otsu(grey));

    const yx = CV.argwhere(binaryImage);
    const meanPoint = LA.mean(yx);
    const yxCentered = LA.subtract(yx, meanPoint);
    const cov = LA.covariance(yxCentered);
    const { eigenvals, eigenvecs } = LA.eigenDecomposition(cov);

    const majorAxisIdx = eigenvals[0] > eigenvals[1] ? 0 : 1;
    const majorAxis = eigenvecs[majorAxisIdx];
    const minorAxis = eigenvecs[1 - majorAxisIdx];

    const majorProj = LA.dot(yxCentered, majorAxis);
    const minorProj = LA.dot(yxCentered, minorAxis);

    // Calculate projections along minor axis
    let majorBins = LA.createBins(
      Math.min(...majorProj),
      Math.max(...majorProj),
      200
    );
    let majorBinIndices = LA.getBinIndices(majorProj, majorBins);
    const minorExtremaBins = LA.getProjectionExtrema(
      minorProj,
      majorBinIndices,
      200,
      0.13 * Math.pow(this.scale, 2)
    );

    const minorProjMin = LA.median(minorExtremaBins.map((x) => x[0]));
    const minorProjMax = LA.median(minorExtremaBins.map((x) => x[1]));

    const minorBins = LA.createBins(minorProjMin, minorProjMax, 50);
    const minorBinIndices = LA.getBinIndices(minorProj, minorBins);
    const majorExtremaBins = LA.getProjectionExtrema(
      majorProj,
      minorBinIndices,
      50,
      0.6 * Math.pow(this.scale, 2)
    );

    const majorProjMin = LA.median(majorExtremaBins.map((x) => x[0]));
    const majorProjMax = LA.median(majorExtremaBins.map((x) => x[1]));
    majorBins = LA.createBins(majorProjMin, majorProjMax, 200);
    majorBinIndices = LA.getBinIndices(majorProj, majorBins);
    let intensity = LA.calculateIntensity(grey, yx, majorBinIndices, 200);
    if (majorAxis[0] < 0) {
      intensity = intensity.reverse();
    }

    const vectors = LA.calculateVectors(
      majorAxis,
      minorAxis,
      majorProjMin,
      majorProjMax,
      minorProjMin,
      minorProjMax
    );
    const [upVec, downVec] =
      vectors.vertical[0][0] > vectors.vertical[1][0]
        ? [vectors.vertical[1], vectors.vertical[0]]
        : [vectors.vertical[0], vectors.vertical[1]];
    const [leftVec, rightVec] =
      vectors.horizontal[0][1] > vectors.horizontal[1][1]
        ? [vectors.horizontal[1], vectors.horizontal[0]]
        : [vectors.horizontal[0], vectors.horizontal[1]];

    let corners = LA.calculateCorners(
      meanPoint,
      upVec,
      downVec,
      leftVec,
      rightVec
    );
    corners = corners.map((corner) => [
      corner[0] + roiBboxLeft,
      corner[1] + roiBboxTop,
    ]);

    // console.log(intensity);
    const { peaks, _ } = LA.findPeaks(intensity, {
      distance: 10,
      prominence: 1.0,
    });
    return corners;
    // console.log(peaks);
    // this.drawStrip(corners);

    // corners = LA.analyzeOrientation(corners, intensity, stripParams);

    // this.cardCtx.clearRect(0, 0, this.cardCanvas.width, this.cardCanvas.height);
    // this.cardCtx.putImageData(CV.convertToImageData(grey), 0, 0);
  }

  getOriginalStripCornersFromNormalized(normalizedStripCorners) {
    const cardStripCorners = normalizedStripCorners.map(([x, y]) => [
      x * this.cardImage.width,
      y * this.cardImage.height,
    ]);
    const imageStripCorners = LA.applyTransformMatrix(
      this.transformMatrix,
      cardStripCorners
    ).map(([x, y]) => [x / this.preProcessScale, y / this.preProcessScale]);
    return imageStripCorners;
  }

  getTransformedStripCorners(offset = [0, 0], scale = 1) {
    const transformedCorners = [
      [0, 0],
      [this.stripSize.width, 0],
      [this.stripSize.width, this.stripSize.height],
      [0, this.stripSize.height],
    ].map(([x, y]) => [(x + offset[0]) * scale, (y + offset[1]) * scale]);
    return transformedCorners;
  }

  getCssTransformMatrix(originalCorners, transformedCorners) {
    const mat = CV.getPerspectiveTransform(originalCorners, transformedCorners);
    const matrixStr = `matrix3d(
        ${mat[0][0]}, ${mat[1][0]}, 0, ${mat[2][0]},
        ${mat[0][1]}, ${mat[1][1]}, 0, ${mat[2][1]},
        0, 0, 1, 0,
        ${mat[0][2]}, ${mat[1][2]}, 0, ${mat[2][2]}
      )`;
    return matrixStr;
  }

  getStripCorners(normalizedStripCorners, offset = [0, 0], scale = 1) {
    const cardStripCorners = normalizedStripCorners.map(([x, y]) => [
      x * this.cardImage.width,
      y * this.cardImage.height,
    ]);
    const imageStripCorners = LA.applyTransformMatrix(
      this.transformMatrix,
      cardStripCorners
    ).map(([x, y]) => [x / this.preProcessScale, y / this.preProcessScale]);
    const transformedCorners = [
      [0, 0],
      [this.stripSize.width, 0],
      [this.stripSize.width, this.stripSize.height],
      [0, this.stripSize.height],
    ].map(([x, y]) => [(x + offset[0]) * scale, (y + offset[1]) * scale]);
    const mat = CV.getPerspectiveTransform(
      imageStripCorners,
      transformedCorners
    );
    const matrixStr = `matrix3d(
        ${mat[0][0]}, ${mat[1][0]}, 0, ${mat[2][0]},
        ${mat[0][1]}, ${mat[1][1]}, 0, ${mat[2][1]},
        0, 0, 1, 0,
        ${mat[0][2]}, ${mat[1][2]}, 0, ${mat[2][2]}
      )`;
    const padLocations = this.padLocations.map((y) => [
      (offset[0] + this.stripSize.width / 2) * scale,
      (offset[1] + y) * scale,
    ]);
    return {
      originalCorners: imageStripCorners,
      transformedCorners: transformedCorners,
      cssTransformMatrix: matrixStr,
      padLocations: padLocations,
    };
  }
}

class ImageCardDetectorViz {
  constructor(imageCanvas, cardCanvas, status) {
    this.imageCanvas = imageCanvas;
    this.cardCanvas = null;
    if (cardCanvas) {
      this.cardCanvas = cardCanvas;
    } else {
      this.cardCanvas = document.createElement("canvas"); // TODO: Remove card canvas code
      this.cardCanvas.style.display = "none";
      document.body.appendChild(this.cardCanvas);
    }
    this.imageCtx = this.imageCanvas.getContext("2d");
    this.cardCtx = this.cardCanvas.getContext("2d");
    if (status) {
      this.status = status;
    } else {
      this.status = document.createElement("div");
    }
    this.detector = new ImageCardDetector();
  }

  setImageCanvasSize(width, height) {
    this.imageCanvas.width = width;
    this.imageCanvas.height = height;
  }

  setCardCanvasSize(height) {
    // this.scale = height / this.CARD_HEIGHT;
    const scale = height / this.detector.cardHeight;
    this.cardCanvas.width = this.detector.cardWidth * scale;
    this.cardCanvas.height = height; // this.CARD_HEIGHT * this.scale;
    this.cardCanvas.style.position = "relative";
  }

  drawImage(img, width, height) {
    this.imageCtx.drawImage(img, 0, 0, width, height);
  }

  applyColorCorrection(ccm) {
    const imageData = this.imageCtx.getImageData(
      0,
      0,
      this.imageCanvas.width,
      this.imageCanvas.height
    );
    const newImageData = CV.applyColorMatrix(imageData, ccm);
    this.imageCtx.putImageData(newImageData, 0, 0);
  }

  processImage() {
    const imageData = this.imageCtx.getImageData(
      0,
      0,
      this.imageCanvas.width,
      this.imageCanvas.height
    );
    const isValid = this.detector.processImage(imageData);
    this.status.textContent = this.detector.status;
    if (this.detector.cardImage) {
      this.setCardCanvasSize(this.imageCanvas.height);
      this.cardCtx.clearRect(
        0,
        0,
        this.cardCanvas.width,
        this.cardCanvas.height
      );
      const resultImage = CV.convertToImageData(this.detector.cardImage);
      const scale = this.cardCanvas.width / resultImage.width;
      this.cardCtx.putImageData(CV.resizeImageData(resultImage, scale), 0, 0);
    }
    return isValid;
  }

  loadImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.processImage(img);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  drawCard() {
    const corners = this.detector.orderedCorners;
    this.imageCtx.strokeStyle = "#00ff00";
    this.imageCtx.lineWidth = 3;

    this.imageCtx.beginPath();
    this.imageCtx.moveTo(corners[0].x, corners[0].y);
    for (let i = 1; i < 4; i++) {
      this.imageCtx.lineTo(corners[i].x, corners[i].y);
    }
    this.imageCtx.closePath();
    this.imageCtx.stroke();

    this.imageCtx.fillStyle = "#ff0000";
    const labels = ["TL", "TR", "BR", "BL"];
    corners.forEach((corner, i) => {
      this.imageCtx.beginPath();
      this.imageCtx.arc(corner.x, corner.y, 5, 0, 2 * Math.PI);
      this.imageCtx.fill();

      this.imageCtx.fillStyle = "#ffffff";
      this.imageCtx.strokeStyle = "#000000";
      this.imageCtx.lineWidth = 2;
      this.imageCtx.font = "16px Arial";
      this.imageCtx.strokeText(labels[i], corner.x + 10, corner.y + 10);
      this.imageCtx.fillText(labels[i], corner.x + 10, corner.y + 10);
    });
  }

  drawRoi() {
    const roiPoints = this.detector.roiPoints;

    this.imageCtx.strokeStyle = "#0000ff";
    this.imageCtx.lineWidth = 5;

    this.imageCtx.beginPath();
    this.imageCtx.moveTo(roiPoints[0].x, roiPoints[0].y);
    for (let i = 1; i < 4; i++) {
      this.imageCtx.lineTo(roiPoints[i].x, roiPoints[i].y);
    }
    this.imageCtx.closePath();
    this.imageCtx.stroke();
  }

  drawColorGrid(colorBlockCells) {
    colorBlockCells.forEach((cell) => {
      const corners = cell.corners;
      this.imageCtx.fillStyle = "#ff0000";
      this.imageCtx.strokeStyle = "#ff0000";
      this.imageCtx.lineWidth = 1;
      this.imageCtx.beginPath();
      this.imageCtx.moveTo(corners[0][0], corners[0][1]);
      for (let k = 1; k < 4; k++) {
        this.imageCtx.lineTo(corners[k][0], corners[k][1]);
      }
      this.imageCtx.closePath();
      this.imageCtx.stroke();
    });
  }

  drawColorGrids() {
    this.drawColorGrid(this.detector.leftColorBlockCells);
    this.drawColorGrid(this.detector.rightColorBlockCells);
  }

  drawMarkers() {
    this.detector.markerIds.forEach((id) => {
      const markerCorners = this.detector.markerCorners[id];
      this.imageCtx.strokeStyle = "#ff0000";
      this.imageCtx.lineWidth = 2;
      this.imageCtx.beginPath();
      this.imageCtx.moveTo(markerCorners[0].x, markerCorners[0].y);
      for (let i = 1; i < 4; i++) {
        this.imageCtx.lineTo(markerCorners[i].x, markerCorners[i].y);
      }
      this.imageCtx.closePath();
      this.imageCtx.stroke();

      const center = {
        x: markerCorners.reduce((sum, corner) => sum + corner.x, 0) / 4,
        y: markerCorners.reduce((sum, corner) => sum + corner.y, 0) / 4,
      };
      this.imageCtx.fillStyle = "#ffffff";
      this.imageCtx.strokeStyle = "#000000";
      this.imageCtx.lineWidth = 2;
      this.imageCtx.font = "16px Arial";
      this.imageCtx.strokeText(id, center.x, center.y);
      this.imageCtx.fillText(id, center.x, center.y);
    });
  }

  drawStrip(corners) {
    this.imageCtx.strokeStyle = "#ff0000";
    this.imageCtx.lineWidth = 3;

    this.imageCtx.beginPath();
    this.imageCtx.moveTo(corners[0][0], corners[0][1]);
    for (let i = 1; i < 4; i++) {
      this.imageCtx.lineTo(corners[i][0], corners[i][1]);
    }
    this.imageCtx.closePath();
    this.imageCtx.stroke();

    this.imageCtx.fillStyle = "#ff0000";
    corners.forEach((corner, i) => {
      this.imageCtx.beginPath();
      this.imageCtx.arc(corner[0], corner[1], 5, 0, 2 * Math.PI);
      this.imageCtx.fill();

      this.imageCtx.fillStyle = "#ffffff";
      this.imageCtx.strokeStyle = "#000000";
      this.imageCtx.lineWidth = 2;
    });
  }
}

this.ImageCardDetector = ImageCardDetector;
this.ImageCardDetectorViz = ImageCardDetectorViz;
