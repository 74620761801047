import { IScanResult } from "@/types/ScanResult";
import { memo } from "react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { reagentColorToRgbString } from "@/utils/scanUtils";
import { animated, useSpringRef, useSprings } from "@react-spring/web";
import { useEffect, useMemo } from "react";

interface IProps {
  stripPhoto: any;
  onAnimationIdle: () => any;
  testResultData?: IScanResult;
}

interface IAnimationProps {
  scanResult?: IScanResult;
  colorsCount?: number;
  onAnimationIdle?: () => void;
}

const StripPhotoImage = memo(function ({
  photoFile,
  className,
}: {
  photoFile: any;
  className?: string;
}) {
  if (!(photoFile instanceof File)) {
    return null;
  } else {
    return (
      <img
        src={URL.createObjectURL(photoFile)}
        alt="Strip"
        className={className}
      />
    );
  }
});

const DEFAULT_COLOR = "rgba(30, 30, 30, 0.1)";

function AnalyzeAnimation({
  scanResult,
  colorsCount = 10,
  onAnimationIdle = () => {},
}: IAnimationProps) {
  const springRef = useSpringRef();
  const isLargerScreen = useMediaQuery("(min-width: 480px)");

  const colors = useMemo(() => {
    if (scanResult) {
      let colors = Object.values(scanResult.reagent_colors).map(
        reagentColorToRgbString
      );

      if (colors.length > 0 && colors.length < colorsCount) {
        do {
          colors = [...colors, ...colors];
        } while (colors.length < colorsCount);
      }

      return colors.slice(0, colorsCount);
    } else {
      return Array(colorsCount).fill(DEFAULT_COLOR);
    }
  }, [colorsCount, scanResult]);

  const [springs] = useSprings(
    colors.length,
    (i) => {
      return {
        ref: springRef,
        from: { background: DEFAULT_COLOR },
        to: { background: colors[i] || DEFAULT_COLOR },
        config: { mass: 10, tension: 200, friction: 50, clamp: true },
        delay: i * 200,
        onResolve: () => {
          if (springRef.current.every((item) => item.idle)) {
            onAnimationIdle();
          }
        },
      };
    },
    [colors]
  );

  useEffect(() => {
    if (scanResult) {
      springRef.start();
    }
  }, [scanResult, springRef]);

  return (
    <div
      className="grid gap-[10px]"
      style={{
        gridTemplateColumns: isLargerScreen
          ? `repeat(${colorsCount}, 1fr)`
          : `repeat(${Math.ceil(colorsCount / 2)}, 1fr)`,
      }}
    >
      {springs.map((props, index) => {
        return (
          <animated.div
            style={props}
            key={index}
            className="aspect-square border border-gray-300 rounded-sm "
          ></animated.div>
        );
      })}
    </div>
  );
}

export default function SimpleAnimation({
  stripPhoto,
  onAnimationIdle,
  testResultData,
}: IProps) {
  return (
    <>
      <StripPhotoImage
        photoFile={stripPhoto}
        className="rounded-lg max-h-[70vh] mx-auto mb-8"
      />
      <h2 className="text-center font-bold text-2xl mb-5">
        Analyzing test strip...
      </h2>
      <AnalyzeAnimation
        scanResult={testResultData}
        colorsCount={14}
        onAnimationIdle={onAnimationIdle}
      />
    </>
  );
}
