import { EPredictionsParameterStatus } from "@/types/common";
import { COLORS } from "@/utils/colors";
import { getPredictionsParameterStatus } from "@/utils/common";
import { PREFIX } from "@/utils/variables";
import {
  useSpring,
  animated,
  useSprings,
  config as springConfig,
} from "@react-spring/web";

interface IProps {
  value: number;
  predictionParameterName: string;
  delay?: number;
  duration?: number;
  animate: boolean;
}

const barColors = {
  red: "#EE6365",
  green: "#99CB67",
  yellow: "#FA974C",
};

export default function MeasurementBar({
  value,
  predictionParameterName,
  delay = 0,
  duration = 300,
  animate,
}: IProps) {
  const max = Math.max(PREFIX[predictionParameterName].length - 1, 0);
  const parameterStatus = getPredictionsParameterStatus(
    predictionParameterName,
    value
  );

  let color =
    parameterStatus === EPredictionsParameterStatus.High
      ? barColors.red
      : parameterStatus === EPredictionsParameterStatus.Medium
      ? barColors.yellow
      : barColors.green;

  const c = 100 / (max + 1);
  const offset = c * value + c / 2;

  const [fragmentSprings, fragmentSpringApi] = useSprings(
    max + 1,
    (springIndex: number) => {
      const isActive = springIndex === value;

      const from = {
        background: COLORS.slate["700"],
      };
      const to = {
        background: isActive && animate ? color : from.background,
      };

      return {
        from,
        to,
        delay: delay + duration + 100,
        config: {
          ...springConfig.gentle,
        },
      };
    },
    [delay, duration, value, animate]
  );

  const [pointerSpring, pointerApi] = useSpring(() => {
    const from = {
      left: "0%",
      opacity: 0,
    };
    const to = [
      { opacity: animate ? 1 : 0 },
      { left: animate ? offset + "%" : from.left },
    ];

    return {
      from,
      to,
      delay: delay + 300,
      config: {
        duration: duration,
      },
    };
  }, [offset, animate, delay]);

  return (
    <div className="w-full pt-[20px]">
      <div className="w-full relative mb-[5px] h-[8px]">
        <animated.div
          style={{
            ...pointerSpring,
            width: 10,
            height: 12,
            clipPath: "polygon(0 50%, 50% 100%,100% 50%,50% 0)",
          }}
          className="bg-white absolute bottom-[2px] -translate-x-2/4"
        ></animated.div>
      </div>
      <div
        className="w-full grid"
        style={{ gridTemplateColumns: `repeat(${max + 1}, 1fr)`, gap: 6 }}
      >
        {fragmentSprings.map((props, index) => (
          <animated.div
            key={index}
            style={props}
            className="h-[3px] rounded"
          ></animated.div>
        ))}
      </div>
    </div>
  );
}
