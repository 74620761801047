import { cn } from "@/lib/utils";
import {
  NUMERIC_MEASUREMENT,
  NUMERIC_MEASUREMENT_UNITS,
} from "@/utils/variables";
import { animated, useSpring, config as configPreset } from "@react-spring/web";
import MeasurementBar from "./MeasurementBar";

interface IProps {
  show: boolean;
  index: number;
  prediction: {
    key: string;
    value: number;
  };
  onAnimationRest?: (rowIndex: number) => void;
}

const labelsMap: { [x: string]: string } = {
  leu: "Leu.",
  nit: "Nit.",
  uro: "Uro.",
  pro: "Pro.",
  ph: "pH.",
  blo: "Blo.",
  sg: "S.G.",
  ket: "Ket.",
  bil: "Bil.",
  glu: "Glu.",
};

export default function ResultRow({
  prediction,
  show,
  index,
  onAnimationRest = () => {},
}: IProps) {
  const measurementValue =
    NUMERIC_MEASUREMENT[prediction.key][prediction.value];
  const measurementUnits = NUMERIC_MEASUREMENT_UNITS[prediction.key];
  const measurementText = `${measurementValue} ${measurementUnits}`.trim();
  const duration = 350;
  const delay = index * (duration - 150);

  const [containerSpring, containerApi] = useSpring(() => {
    const from = {
      opacity: 0,
      y: "50px",
      scale: 1.2,
    };

    const to = {
      opacity: show ? 1 : from.opacity,
      y: show ? "0px" : from.y,
      scale: show ? 1 : from.scale,
    };

    return {
      from,
      to,
      config: { duration, ...configPreset.gentle },
      delay,
      onRest: () => {
        onAnimationRest(index);
      },
    };
  }, [show]);

  const [labelSpring, labelApi] = useSpring(() => {
    const from = {
      x: "-100px",
    };

    const to = {
      x: show ? "0px" : from.x,
    };

    return {
      from,
      to,
      config: { ...configPreset.gentle, duration },
      delay,
    };
  }, [show]);

  const [measurementSpring, measurementApi] = useSpring(() => {
    const from = {
      x: "100px",
    };

    const to = {
      x: show ? "0px" : from.x,
    };

    return {
      from,
      to,
      config: { ...configPreset.gentle, duration },
      delay,
    };
  }, [show]);

  return (
    <animated.div
      style={containerSpring}
      className="grid grid-cols-analysis-screen-results text-ribbon-slate-300 auto-rows-[42px]"
    >
      <div className="px-2 text-center border-b border-ribbon-slate-300 flex flex-col justify-center">
        <animated.span style={labelSpring}>
          {labelsMap[prediction.key]}
        </animated.span>
      </div>
      <div></div>
      <div className={cn("px-2 flex flex-col justify-end  translate-y-[1px]")}>
        <MeasurementBar
          value={prediction.value}
          predictionParameterName={prediction.key}
          animate={show}
          delay={delay}
          duration={duration + 200}
        />
      </div>
      <div className="px-2 text-center border-b border-ribbon-slate-300 flex flex-col justify-center">
        <animated.span style={measurementSpring}>
          {measurementText}
        </animated.span>
      </div>
    </animated.div>
  );
}
