import { useEffect, useRef } from "react";

export default function useAnimationFrame(callback: Function) {
  const animationFrameRequestIdRef = useRef<number | null>(null);

  useEffect(() => {
    const animate = () => {
      animationFrameRequestIdRef.current =
        window.requestAnimationFrame(animate);
      callback();
    };
    animate();

    return () => {
      if (animationFrameRequestIdRef.current) {
        window.cancelAnimationFrame(animationFrameRequestIdRef.current);
      }
    };
  }, [callback]);
}
