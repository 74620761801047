import { supabaseService } from "@/services/supabaseService";
import { IScanResult } from "@/types/ScanResult";
import {
  LAST_TEST_DATETIME_STORAGE_KEY,
  QUERY_KEY,
  SERVER_URL,
} from "@/utils/variables";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import posthog from "posthog-js";
import { scanUtilsService } from "@/services/scanUtilsService";
import { DateTime } from "luxon";
import { getNumericEnvVariable } from "@/utils/common";
import useTimer from "@/hooks/useTimer";
import AlertMP3 from "@/assets/audio/alert.mp3";

type StripPhoto = File | undefined;

interface ITestContext {
  stripPhoto?: StripPhoto;
  setStripPhoto: (file: StripPhoto) => void;
  analyzeStripPhoto: (file: File, userID?: string) => Promise<any>;
  testResultData: IScanResult | undefined;
  error?: any;
  stripDevelopmentStart?: number;
  setStripDevelopmentStart: (val: number | undefined) => void;
  developTimer: any;
  takePhotoTimer: any;
  stopAlarmSound: () => void;
  playAlarmSound: () => void;
  resetContext: () => void;
}

const defaultValue: ITestContext = {
  stripPhoto: undefined,
  setStripPhoto: () => {},
  analyzeStripPhoto: async () => {},
  testResultData: undefined,
  error: null,
  stripDevelopmentStart: undefined,
  setStripDevelopmentStart: () => {},
  developTimer: {},
  takePhotoTimer: {},
  stopAlarmSound: () => {},
  playAlarmSound: () => {},
  resetContext: () => {},
};

const TestContext = createContext(defaultValue);

const developTimerLength = getNumericEnvVariable(
  process.env.REACT_APP_DEVELOP_STRIP_TIMER_LENGTH,
  95
);
const takePhotoTimerLength = getNumericEnvVariable(
  process.env.REACT_APP_TAKE_PHOTO_TIMER_LENGTH,
  60
);

const audioElement = new Audio(AlertMP3);
audioElement.loop = true;

function TestContextProvider({ children }: { children?: ReactNode }) {
  const [stripPhoto, setStripPhoto] = useState<StripPhoto>(undefined);
  const [testResultData, setTestResultData] = useState(
    defaultValue.testResultData
  );
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const supabase = supabaseService.getClient();
  const [stripDevelopmentStart, setStripDevelopmentStart] = useState(
    defaultValue.stripDevelopmentStart
  );
  const developTimer = useTimer({ time: developTimerLength });
  const takePhotoTimer = useTimer({ time: takePhotoTimerLength });

  const stopAlarmSound = useCallback(() => {
    try {
      audioElement.pause();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const playAlarmSound = useCallback(() => {
    try {
      audioElement.play().catch((error) => {
        console.error("Unable to play audio:", error);
      });
    } catch (error) {
      console.error(error);
    }
    setTimeout(stopAlarmSound, 6000);
  }, [stopAlarmSound]);

  const mutationSaveResultToSupabase = useMutation({
    mutationFn: async (data: { resultData: IScanResult; userId: string }) => {
      await scanUtilsService.linkScanWithUser(
        data.resultData.scan_uuid,
        data.userId
      );
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.scanResults] });
    },
    onError: (error: Error) => {
      console.error("Error saving scan results to database:", error);
    },
  });

  const saveResultData = useCallback(
    async (resultData: IScanResult) => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      try {
        if (session?.user) {
          // save result to the database if the user logged in
          await mutationSaveResultToSupabase.mutateAsync({
            resultData,
            userId: session.user.id,
          });
        } else {
          // otherwise save it to the local storage
          scanUtilsService.saveResultToLocalStorage(resultData);
        }
      } catch (error) {
        console.error("Error saving scan results");
      }
    },
    [mutationSaveResultToSupabase, supabase.auth]
  );

  const updateStripDevelopmentTime = useCallback(
    async (
      scanUUID: string,
      developmentStart: number,
      developmentEnd: number
    ) => {
      try {
        const time = (developmentEnd - developmentStart) / 1000;
        await supabase.rpc("update_strip_development_time", {
          p_scan_uuid: scanUUID,
          p_strip_development_time: time,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [supabase]
  );

  const handleAnalyzeStripError = useCallback(async (error: any) => {
    setError(error);

    if (error.response?.data?.failed_image_path) {
      try {
        await scanUtilsService.saveScanFailure({
          ...error.response.data,
          user_id: null,
          date_of_failure: DateTime.local().toISO(),
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const analyzeStripPhoto = useCallback(
    async (photoFile: File, userID?: string) => {
      try {
        setError(null);
        setStripPhoto(photoFile);
        const formData = new FormData();
        formData.append("image", photoFile);
        if (userID) {
          formData.append("user_id", userID);
        }
        const response = await axios.post<any, AxiosResponse<IScanResult>>(
          SERVER_URL || "",
          formData
        );
        setTestResultData(response.data);
        saveResultData(response.data);

        if (stripDevelopmentStart !== undefined) {
          const stripDevelopmentEnd = Date.now();
          const stripDevelopmentTime =
            (stripDevelopmentStart - stripDevelopmentEnd) / 1000;

          updateStripDevelopmentTime(
            response.data.scan_uuid,
            stripDevelopmentStart,
            stripDevelopmentEnd
          );

          if (["prod", "stg"].includes(process.env.REACT_APP_ENV || "")) {
            posthog.capture("scan_succeeded", {
              scan_order_no: response.data.scan_order_no,
              scan_uuid: response.data.scan_uuid,
              strip_development_time: stripDevelopmentTime,
            });
          }

          if (typeof localStorage !== "undefined" && localStorage !== null) {
            localStorage.setItem(
              LAST_TEST_DATETIME_STORAGE_KEY,
              DateTime.now().toISO()
            );
          }
        }

        return response.data;
      } catch (error: any) {
        handleAnalyzeStripError(error);
      }
    },
    [
      saveResultData,
      stripDevelopmentStart,
      updateStripDevelopmentTime,
      handleAnalyzeStripError,
    ]
  );

  const resetContext = useCallback(() => {
    developTimer.reset();
    takePhotoTimer.reset();
    setStripDevelopmentStart(undefined);
  }, [developTimer, takePhotoTimer]);

  return (
    <TestContext.Provider
      value={{
        stripPhoto,
        setStripPhoto,
        analyzeStripPhoto,
        testResultData,
        error,
        stripDevelopmentStart,
        setStripDevelopmentStart,
        developTimer,
        takePhotoTimer,
        playAlarmSound,
        stopAlarmSound,
        resetContext,
      }}
    >
      {children}
    </TestContext.Provider>
  );
}

const useTestContext = () => useContext(TestContext);

export { TestContextProvider, useTestContext };
