import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Header from "@/components/Header";
import { Button } from "@/components/ui/button";
import { Clock2, House } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import ScanResult from "@/components/ScanResult";
import SendResultPdfModal from "@/components/SendResultPdfModal/SendResultPdfModal";
import { ORDER_TEST_KITS_URL } from "@/utils/variables";
import { Chance } from "chance";
import Cookies from "js-cookie";
import { isNumber } from "lodash";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { PopupButton } from "@typeform/embed-react";
import { toast } from "sonner";
import { useAppContext } from "@/contexts/appContext";
import Cart from "@/components/icons/Cart";
import Email from "@/components/icons/Email";
import AddProfile from "@/components/icons/AddProfile";
import History from "@/components/icons/History";
import Chatbot from "@/components/Chatbot/Chatbot";

const TYPEFORM_SURVEY_ID = process.env.REACT_APP_TYPEFORM_POST_SCAN_SURVEY_ID;
const TYPEFORM_SURVEY_PROBABILITY = Number(
  process.env.REACT_APP_POST_SCAN_SURVEY_PROBABILITY
);
const TYPEFORM_SURVEY_DELAY = Number(
  process.env.REACT_APP_POST_SCAN_SURVEY_DELAY_MILISECONDS
);
const SHOWN_SURVEY_ID_COOKIE_KEY = "shown_post_scan_survey_id";
const chance = new Chance();

function ResultPage() {
  const location = useLocation();
  const resultData = location.state?.resultData;
  const stripPhoto = location.state?.stripPhoto;
  const resultDate: string | undefined = location.state?.resultDate;
  const navigate = useNavigate();
  const [openEmailPdfModal, setOpenEmailPdfModal] = useState(false);
  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);
  const typeformPopupRef = useRef();
  const { isLoggedIn } = useAppContext();

  const cancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const openTypeformPopup = useCallback(() => {
    if (typeformPopupRef.current) (typeformPopupRef.current as any).open();
    setOpenSurveyDialog(false);
  }, []);

  const showTypeformSurveyDialog = useCallback(() => {
    const shownSurveyId = Cookies.get(SHOWN_SURVEY_ID_COOKIE_KEY);

    if (!TYPEFORM_SURVEY_ID || shownSurveyId) return;

    const showDialogProbability =
      isNumber(TYPEFORM_SURVEY_PROBABILITY) &&
      isFinite(TYPEFORM_SURVEY_PROBABILITY)
        ? Math.min(TYPEFORM_SURVEY_PROBABILITY * 100, 100)
        : 0;
    const showDialogDelay =
      isNumber(TYPEFORM_SURVEY_DELAY) && isFinite(TYPEFORM_SURVEY_DELAY)
        ? TYPEFORM_SURVEY_DELAY
        : 0;
    const shouldDisplayDialog = chance.bool({
      likelihood: showDialogProbability,
    });

    if (shouldDisplayDialog) {
      setTimeout(() => {
        setOpenSurveyDialog(true);
        Cookies.set(SHOWN_SURVEY_ID_COOKIE_KEY, TYPEFORM_SURVEY_ID);
      }, showDialogDelay);
    }
  }, []);

  const handleSubmitTypeformSurvey = useCallback(() => {
    setOpenSurveyDialog(false);
    (typeformPopupRef.current as any).close();
    toast.success("Thank you for completing this survey!");
  }, []);

  useEffect(() => {
    showTypeformSurveyDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resultData) {
    return <Navigate to="/test" replace />;
  }

  return (
    <>
      <Header>
        <div className="mx-auto max-w-screen-sm px-6 py-5 grid grid-cols-header gap-4 items-center">
          <div></div>
          <div className="flex flex-col items-center justify-center">
            <div className="font-bold">Ribbon Checkup Results</div>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={cancel}
            className="text-ribbon-cobalt-700"
          >
            <House size={30} />
          </Button>
        </div>
      </Header>
      <div className="max-w-screen-sm w-full mx-auto px-5 pb-6">
        <div className="space-y-3">
          {resultDate && (
            <div className="flex items-center space-x-2 text-ribbon-stone-700">
              <Clock2 size={18} className="text-ribbon-stone-300" />
              <div>
                {DateTime.fromISO(resultDate)
                  .setLocale("en-US")
                  .toFormat("MM/dd/yyyy | t")}
              </div>
            </div>
          )}
          <div className="grid grid-cols-3 gap-[2px] rounded-lg overflow-clip">
            <Button
              onClick={() => setOpenEmailPdfModal(true)}
              variant="ghost"
              className="rounded-none bg-ribbon-stone-300 flex-col items-center space-y-2 py-1 h-20"
            >
              <Email className="h-6" />
              <span>Email</span>
            </Button>
            <Button
              asChild
              variant="ghost"
              className="rounded-none bg-ribbon-stone-300 flex-col items-center space-y-2 py-1 h-20"
            >
              <a
                href={ORDER_TEST_KITS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Cart className="h-6 text-ribbon-cobalt-700" />
                <span>Order</span>
              </a>
            </Button>
            {isLoggedIn ? (
              <>
                <Button
                  asChild
                  variant="ghost"
                  className="rounded-none bg-ribbon-stone-300 flex-col items-center space-y-2 py-1 h-20"
                >
                  <Link to="/test-history">
                    <History className="h-6" />
                    <span>History</span>
                  </Link>
                </Button>
              </>
            ) : (
              <>
                <Button
                  asChild
                  variant="ghost"
                  className="rounded-none bg-ribbon-stone-300 flex-col items-center space-y-2 py-1 h-20"
                >
                  <Link to="/auth">
                    <AddProfile className="h-6" />
                    <span>Save</span>
                  </Link>
                </Button>
              </>
            )}
          </div>
          <ScanResult
            data={resultData}
            testPhotoFileOrObjectPath={stripPhoto}
          />
          <Chatbot data={resultData} userId={resultData.scan_uuid} />
        </div>
      </div>
      <SendResultPdfModal
        open={openEmailPdfModal}
        onOpenChange={setOpenEmailPdfModal}
        resultData={resultData}
      />
      {TYPEFORM_SURVEY_ID && (
        <>
          <Dialog open={openSurveyDialog} onOpenChange={setOpenSurveyDialog}>
            <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
              <DialogHeader></DialogHeader>
              <DialogTitle className="text-center text-2xl font-medium">
                <VisuallyHidden>Survey Invitation</VisuallyHidden>
                <span>Review your experience for a $25 Amazon Gift Card?</span>
                <span>
                  You will be returned to your results after completing the
                  survey.
                </span>
              </DialogTitle>
              <DialogDescription>
                Review your experience for a $25 Amazon Gift Card?
                You will be returned to your results after completing the
                survey.
              </DialogDescription>
              <Button onClick={openTypeformPopup}>Take Survey</Button>
            </DialogContent>
          </Dialog>
          <PopupButton
            embedRef={typeformPopupRef}
            id={TYPEFORM_SURVEY_ID}
            onSubmit={handleSubmitTypeformSurvey}
          />
        </>
      )}
    </>
  );
}

export default ResultPage;
