import { IScanResult } from "@/types/ScanResult";
import { supabaseService } from "./supabaseService";
import { Session } from "@supabase/supabase-js";
import { QUERY_KEY } from "@/utils/variables";
import { queryClient as appQueryClient } from "@/queryClient";
import { toast } from "sonner";
import { IScanFailure } from "@/types/ScanFailure";

const SAVED_RESULTS_STORAGE_KEY = "ribbon_checkup_saved_results";
const SAVED_SCAN_FAILURES_STORAGE_KEY = "ribbon_checkup_saved_scan_failures";

class ScanUtilsService {
  private safeGetArrayValueFromLocalStorage(storageKey: string) {
    if (typeof localStorage === "undefined" || localStorage === null)
      return null;

    const storageContents = localStorage.getItem(storageKey);
    try {
      if (storageContents === null)
        throw Error("No saved results in local storage");
      const parsed = JSON.parse(storageContents);
      if (!Array.isArray(parsed)) throw Error("Invalid saved results contents");
      return parsed;
    } catch (error) {
      return [];
    }
  }

  public getSavedResultsFromStorage() {
    return this.safeGetArrayValueFromLocalStorage(SAVED_RESULTS_STORAGE_KEY);
  }

  public saveResultToLocalStorage(result: IScanResult) {
    const storageContents = this.getSavedResultsFromStorage();

    if (storageContents === null) {
      return;
    }

    localStorage.setItem(
      SAVED_RESULTS_STORAGE_KEY,
      JSON.stringify([...storageContents, result])
    );
  }

  public async linkScanWithUser(scanId: string, userId: string) {
    const payload = {
      user_uid: userId,
      scan_uid: scanId,
    };

    const { error } = await supabaseService
      .getClient()
      .from("UserScan")
      .insert(payload);

    if (error) throw error;
  }

  public async linkSavedResultsWithAccount(session: Session) {
    const savedResults = this.getSavedResultsFromStorage();

    if (savedResults === null) {
      return;
    }

    if (savedResults.length === 0) return;
    const supabase = supabaseService.getClient();

    try {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*")
        .eq("user_uid", session.user.id)
        .order("created_at", { ascending: false });
      if (error) throw error;

      let linkedCount = 0;
      for (const storageResult of savedResults) {
        const resultId = storageResult.scan_uuid;
        const foundAlreadyLinked = data.find(
          (userScan) => userScan.scan_uid === resultId
        );
        if (foundAlreadyLinked) continue;
        await this.linkScanWithUser(storageResult.scan_uuid, session.user.id);
        linkedCount++;
      }

      if (linkedCount > 0) {
        appQueryClient.invalidateQueries({ queryKey: [QUERY_KEY.scanResults] });
        if (typeof localStorage !== "undefined" && localStorage !== null) {
          localStorage.removeItem(SAVED_RESULTS_STORAGE_KEY);
        }
        toast.success("Results have been saved to your test history.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  private saveScanFailureToStorage(scanFailure: IScanFailure) {
    const storageContents = this.safeGetArrayValueFromLocalStorage(
      SAVED_SCAN_FAILURES_STORAGE_KEY
    );

    if (storageContents === null) {
      return;
    }

    storageContents.push(scanFailure);
    localStorage.setItem(
      SAVED_SCAN_FAILURES_STORAGE_KEY,
      JSON.stringify(storageContents)
    );
  }

  public async saveScanFailure(scanFailure: IScanFailure) {
    const supabase = supabaseService.getClient();
    const { data: sessionData, error } = await supabase.auth.getSession();
    if (error) throw error;

    if (sessionData.session?.user) {
      const { user } = sessionData.session;
      const { error } = await supabase
        .from("ScanFailures")
        .insert({ ...scanFailure, user_id: user.id });
      if (error) throw error;
    } else {
      this.saveScanFailureToStorage(scanFailure);
    }
  }

  public async saveScanFailuresFromStrorage(session: Session) {
    const storageContents = this.safeGetArrayValueFromLocalStorage(
      SAVED_SCAN_FAILURES_STORAGE_KEY
    );
    const user = session.user;

    if (storageContents === null) {
      return;
    }

    for (const scanFailure of storageContents) {
      const { error } = await supabaseService
        .getClient()
        .from("ScanFailures")
        .insert({ ...scanFailure, user_id: user.id });
      if (error) console.error(error);
    }

    localStorage.removeItem(SAVED_SCAN_FAILURES_STORAGE_KEY);
  }
}

const scanUtilsService = new ScanUtilsService();

supabaseService.getClient().auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_IN" && session) {
    console.info("Linking scan results with the account..");
    scanUtilsService.linkSavedResultsWithAccount(session);
    // Link scan failures as well
    scanUtilsService.saveScanFailuresFromStrorage(session);
  }
});

export { scanUtilsService };
