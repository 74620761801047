import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

// Setup analytics/session tracking in prod
// routes.js also has tracking code
if (["prod", "stg"].includes(process.env.REACT_APP_ENV)) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY);
  console.log("PostHog initialized");

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      posthog.sentryIntegration({
        organization: process.env.REACT_APP_SENTRY_ORG_URL,
        projectId: process.env.REACT_APP_SENTRY_PROJECT_ID,
        severityAllowList: ['error', 'info']
      }),
      Sentry.browserTracingIntegration(),
    ],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
